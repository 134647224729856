import React from 'react';
import PropTypes from 'prop-types';

import './LayoutBuilder.css';
import RoundedButton from '../RoundedButton';

// import ElementTypeManager from 'kiosk-shared/services/element-type-manager';

class LayoutBuilder extends React.Component {
    render() {
        const screen = this.props.screen;

        if (!screen) return this.misconfigured('Missing screen');
        else if (!screen.config) return this.misconfigured('Missing config');
        else if (!screen.config.layers) return this.misconfigured('Missing layers');

        return this.renderScreen(screen);
    }

    misconfigured(helpfulError) {
        this.toastManager.alert('Misconfigured: ' + helpfulError);

        this.props.popScreen();

        return <div>Misconfigured: {helpfulError}</div>
    }

    renderScreen(screen) {
        return <div className="LayoutBuilder" onClick={() => this.props.popScreen()}>
            {screen.config.layers.map((layer, ix) => {
                if (!layer.elements) return null;

                return <div key={'layer-'+ix} className="LayoutBuilder-layer">{layer.elements.map((element, elIx) => {

                    if (element.type !== 'image') return <div>Unsupport element type: {element.type}</div>
                    else if (!element.image) return <div>Missing image configuration</div>
                    else if (!element.image.mediaId) return <div>Missing image.mediaId configuration</div>

                    const kioskMedia = this.props.kioskMedia;

                    const matchingImages = kioskMedia ? kioskMedia.filter(media => media.id === element.image.mediaId) : [];

                    const style = {
                        height: '100%',
                        position: 'absolute',
                        width: '100%'
                    }

                    const image = matchingImages.length > 0 ? <img src={matchingImages[0].data} style={style} alt=""/> : <div style={{...style, fontStyle: 'italic'}}>Missing image</div>

                    return <div key={'layer-' + ix + '-element-' + elIx} style={style}>{image}</div>
                    // return <Element key={'layer-' + ix + '-element-' + elIx} element={elementConfig}/>
                })}</div>;
            })}
            <div className="LayoutBuilder-close">
                <RoundedButton className="Close" label="X" onClick={() => this.props.popScreen()}/>
            </div>
        </div>
    }

    // constructor(props, context) {
    //     super(props, context);

        // this.elementTypeManager = ElementTypeManager.create();
    // }
}

LayoutBuilder.propTypes = {
    cancelChangeScreen: PropTypes.func.isRequired,
    changeScreen: PropTypes.func.isRequired,
    heartbeat: PropTypes.object.isRequired,
    httpCache: PropTypes.object,
    // kiosk: PropTypes.object,
    kioskMedia: PropTypes.array,
    onExit: PropTypes.func.isRequired,
    popScreen: PropTypes.func.isRequired,
    screen: PropTypes.object.isRequired,
    screens: PropTypes.array.isRequired,
    showChangeScreen: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    updateLastUserInteraction: PropTypes.func.isRequired
};

export default LayoutBuilder