import React from 'react';
import Button from '../../../Button';
import './ButtonTabbedContent.css';

class ButtonTabbedContent extends React.Component {
    render() {
        const buttonTabs = <div className="Brochure-button-row">{this.props.children.map((buttonTab, ix) => {
            const cls = ['BrochureTab'];
            if (ix === this.state.selectedIndex) cls.push('Selected');
            cls.push(buttonTab.props.enabled || ix === this.state.selectedIndex ? 'Enabled' : 'Disabled');

            return <Button key={ix} label={buttonTab.props.label} className={cls.join(' ')} onClick={() => buttonTab.props.enabled ? this.setState({selectedIndex: ix}) : null}/>
        })}</div>;

        const content = <div className="brochure-ButtonTabbedContent-window">
            {this.props.children.map((buttonTab, ix) => (
                <div key={ix} className="brochure-ButtonTabbedContent-content" style={{display: ix === this.state.selectedIndex ? 'block' : 'none'}}>{buttonTab}</div>
            ))}
        </div>;

        return <div className="brochure-ButtonTabbedContent">
            {buttonTabs}
            {content}
        </div>;
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0
        }
    }
}

export default ButtonTabbedContent