import React from 'react';
import PropTypes from 'prop-types';

// Assets
import facebookIcon from '../../../../assets/img/brochure/i_facebook.png';
import twitterIcon from '../../../../assets/img/brochure/i_twitter.png';
import blogIcon from '../../../../assets/img/brochure/i_blog.png';
import './Brochure.css';

// Components
import Button from '../../../Button';
import ButtonTabbedContent from './ButtonTabbedContent';
import ButtonTabContent from './ButtonTabContent';
import CachedImage from '../../../CachedImage';
import EmbeddedMedia from './EmbeddedMedia';
import Map from './Map';
import Share from './Share';

// Contexts
import {LocationContext} from '../../../../contexts/Location';

// Utils
import {LogSpace} from "../../../../services/Stats";

class Brochure extends React.Component {
    saveToSuitcase(saveBrochureCallback) {
        this.setState({savingSuitcase: true});

        saveBrochureCallback(this.props.brochure);

        const resetSuitcase = (function() {
            this.setState({savingSuitcase: false});
        }.bind(this));

        setTimeout(resetSuitcase, 1000);
    }
    render() {
        const brochure = this.props.brochure;
        return <LocationContext.Consumer>{({locale, location, translations, isInSuitcase, saveBrochure, stats, openUrl, httpCache}) => {
            const generalTranslations = translations.general;
            const detailTranslations = translations.brochureDetail;
            const mainWebsite = this.getWebsiteWithLabel('Website');
            const reservationWebsite = this.getWebsiteWithLabel('Reservations');
            const suitcaseLabel = this.state.savingSuitcase ? generalTranslations.saving + '...' : (isInSuitcase(brochure) ? detailTranslations.inSuitcase : detailTranslations.addToSuitcase);

            return <div className="Brochure">
                <div className="Brochure-media">
                    {this.media(httpCache)}
                    {this.props.showDebug === true || this.state.showDebug === true ?
                        <div>
                            {this.props.brochure.externalId}
                            <Button onClick={() => this.setState({showDebug: !this.state.showDebug})}
                                    label={this.state.showDebug ? 'Hide' : 'Keep Visible'}/>
                        </div>
                        :
                        null
                    }
                    <div className="external-id">{brochure.externalId}</div>
                </div>
                <div className="Brochure-details">
                    <div className="section Brochure-button-row">
                        <Button label={generalTranslations.close} onClick={this.handleClose} className="Primary"/>
                        <Button label={suitcaseLabel} onClick={this.saveToSuitcase.bind(this, saveBrochure)}/>
                        {/*<Button label={detailTranslations.print}/>*/}
                        <Button label={detailTranslations.shareWithFriend} onClick={this.toggleSharing.bind(this)}/>
                    </div>

                    { this.state.showSharing ? <Share brochure={this.props.brochure} /> : null }

                    <h1>{this.name(locale)}</h1>

                    <div className="section Brochure-button-row">
                        {mainWebsite === null ? null : <Button label={detailTranslations.website} className="BrochureWebsite" onClick={() => this.openWebsite(mainWebsite, openUrl, stats, location) }/>}
                        {reservationWebsite === null ? null : <Button label={detailTranslations.reservations} className="BrochureWebsite" onClick={() => this.openWebsite(reservationWebsite, openUrl, stats, location)}/>}
                    </div>

                    {this.contactInfo(detailTranslations)}

                    <div className="section">
                        {this.description(locale)}
                    </div>

                    <div className="section Brochure-external-section">
                        <div className="Brochure-external">
                            <ButtonTabbedContent>
                                <ButtonTabContent label={detailTranslations.map} enabled={brochure.addresses.length > 0}>
                                    <Map brochure={brochure}/>
                                </ButtonTabContent>
                                <ButtonTabContent label={detailTranslations.video} enabled={this.getFirstMedia('video') !== null}>
                                    <EmbeddedMedia media={this.getFirstMedia('video')}/>
                                </ButtonTabContent>
                                <ButtonTabContent label={detailTranslations.photos} enabled={this.getFirstMedia('flickr') !== null}>
                                    <EmbeddedMedia media={this.getFirstMedia('flickr')}/>
                                </ButtonTabContent>
                            </ButtonTabbedContent>
                        </div>
                        <div className="Brochure-social">
                            {this.socialLink(httpCache, facebookIcon, "Facebook", locale, openUrl, stats, location)}
                            {this.socialLink(httpCache, twitterIcon, "Twitter", locale, openUrl, stats, location)}
                            {this.socialLink(httpCache, blogIcon, "Blog", locale, openUrl, stats, location)}
                        </div>
                    </div>
                </div>
            </div>;
        }}</LocationContext.Consumer>
    }

    openWebsite(website, fnOpenUrl, stats, location) {
        fnOpenUrl(website.url);
        stats.stat(LogSpace.Listings, {
            listing: this.props.brochure.id,
            location: location.meta.id,
            statType: 'VAC',
            websiteType: website.type,
            url: website.url
        });
    }

    socialLink(httpCache, imgSrc, label, locale, fnOpenUrl, stats, location) {
        const website = this.getWebsiteWithLabel(label);
        const disabled = null === website;

        let link = <CachedImage httpCache={httpCache} src={imgSrc} alt={label}/>

        // if (website) link = <button onClick={e => fnOpenUrl(website.url, this.name(locale) + ' ' + label)} className={'Brochure-social-button' + (disabled?' disabled':'')}>{link}</button>;
        if (website) link = <button onClick={e => this.openWebsite(website, fnOpenUrl, stats, location)} className={'Brochure-social-button' + (disabled?' disabled':'')}>{link}</button>;

        return link;
    }

    handleClose() {
        if (this.props.onClose) this.props.onClose();
    }

    toggleSharing() {
        this.setState({
            showSharing: !this.state.showSharing
        });
    }

    name(locale) {
        return this.translatedValue(this.props.brochure, 'name', locale);
    }

    description(locale) {
        return this.translatedValue(this.props.brochure, 'description', locale);
    }

    translatedValue(brochure, key, locale) {
        // Returned translated value, if available
        if (brochure.translations && brochure.translations[key] && brochure.translations[key][locale] && brochure.translations[key][locale].length > 0) {
            return brochure.translations[key][locale];
        }

        return brochure[key];
    }

    contactInfo(detailtranslations) {
        if (this.props.brochure.addresses.length === 0 && this.props.brochure.phones.length === 0) return null;

        return <div className="section Brochure-contact">
            {this.address(detailtranslations)}
            {this.phones(detailtranslations)}
        </div>
    }

    address(detailTranslations) {
        if (this.props.brochure.addresses.length === 0) return null;
        const address = this.props.brochure.addresses[0];

        let street = address.street1.length > 0 ? <>{address.street1}<br/></> : null;
        let cityState = '';

        if (address.city.length > 0) cityState += address.city;
        if (cityState.length > 0 && address.state.length > 0) cityState += ', ';
        if (address.state.length > 0) cityState += address.state;
        if (cityState.length > 0 && address.postalCode.length > 0) cityState += ' ';
        if (address.postalCode.length > 0) cityState += address.postalCode;
        if (cityState.length > 0 && address.country.length > 0) cityState += ' ';
        if (address.country.length > 0) cityState += address.country;

        return <div className="Brochure-address">
            <label>{detailTranslations.address}:</label><br/>
            {street}{cityState}
        </div>
    }

    phones(detailtranslations) {
        if (this.props.brochure.phones.length === 0) return null;

        const phones = this.props.brochure.phones.map((phone, ix) => {
            return <div key={ix}><label>{detailtranslations.phone}</label> {phone.phone}</div>
        });

        return <div className="Brochure-phone">{phones}</div>
    }

    media(httpCache) {
        const media = this.props.brochure.media;
        const frontImages = media.filter(media => media.mediaKey === 'brochure-front' && media.variation === '0');
        const backImages = media.filter(media => media.mediaKey === 'brochure-back' && media.variation === '0')

        const frontImage = frontImages.length > 0 ? frontImages[0].url : null;
        const backImage = backImages.length > 0 ? backImages[0].url : null;

        let images = [frontImage];
        if (backImage) images.push(backImage);

        return images.map((image, ix) => {
            if (ix !== this.state.currentImage) return null;

            return <CachedImage key={ix} httpCache={httpCache} src={image} alt="Brochure" onClick={e => {
                e.stopPropagation();
                let currentImage = this.state.currentImage + 1;
                if (currentImage > images.length - 1) currentImage = 0;
                this.setState({currentImage});
            }}/>;
        });
    }

    getWebsiteWithLabel(label) {
        const websites = this.props.brochure.websites.filter(website => website.label === label);

        return websites.length > 0 ? websites[0] : null;
    }

    getMedia(mediaKey, variation) {
        return this.props.brochure.media.filter(media => media.mediaKey === mediaKey && (!variation || media.variation === variation));
    }

    getFirstMedia(mediaKey, variation) {
        const media = this.getMedia(mediaKey, variation);

        return media.length > 0 ? media[0] : null;
    }

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            currentImage: 0,
            savingSuitcase: false,
            showSharing: false,
            showDebug: false
        };
    }
}

Brochure.defaultProps = {
    showDebug: false
};

Brochure.propTypes = {
    brochure: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    showDebug: PropTypes.bool
};

export default Brochure