const brochureDetail = {
    publicationDetail: 'Brochure d\'information',
    detail: 'Information',
    address: 'Adresse',
    description: 'Description',
    website: 'Site Internet',
    reservations: 'Réservations',
    phone: 'Phone',
    map: 'Carte',
    video: 'Video',
    photos: 'Photos',
    addToSuitcase: 'Enregistrer',
    inSuitcase: 'Enregistré',
    downloadPrint: 'Télécharger',
    print: 'Imprimer',
    shareWithFriend: 'Partager',
    shareBrochure: 'Partager la brochure',
    shareBrochureDescription: 'Envoyez une copie des informations de cette brochure à vous-même ou à un ami.'
};

export default brochureDetail;