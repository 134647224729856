import React from 'react';
import {LocationContext} from '../../contexts/Location';
import './RotatingBanners.css';
import CachedImage from '../CachedImage';
import { LogSpace } from '../../services/Stats';

class RotatingBanners extends React.Component {
    static contextType = LocationContext;
    render() {
        return <LocationContext.Consumer>{({openUrl, httpCache}) => (
            <div className="banners-RotatingBanners">
                {this.props.banners && this.props.banners.map((banner, ix) => {
                    return <button style={{display: ix === this.state.index ? 'block' : 'none'}} key={banner.placementId + '-' + ix} className="banners-RotatingBanners-banner" onClick={ev => this.handleBannerClick(ev, banner, ix)}>
                        <CachedImage httpCache={httpCache} src={banner.imageUrl} alt="Location banner"/>
                    </button>;
                })}
            </div>
        )}</LocationContext.Consumer>
    }

    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            index: 0
        };
        if (!props.delay) throw new Error('delay is a required prop');
    }

    componentDidMount() {
        this.resetTimer();
        this.triggerBannerChanged();
    }

    componentDidUpdate(prevProps) {
        if (this.didBannersChange(prevProps ? prevProps.banners : null, this.props.banners)) {
            this.setState({index: 0}, () => {
                this.resetTimer();
                this.triggerBannerChanged();
            });
        }
    }

    didBannersChange(prevBanners, curBanners) {
        if (!prevBanners) return true;
        else if (curBanners.length !== prevBanners.length) return true;
        else if (curBanners.filter((curBanner, ix) => !prevBanners[ix] || curBanner.id !== prevBanners[ix].id).length > 0) return true;

        return false;
    }

    resetTimer() {
        if (this.timer) clearInterval(this.timer);
        if (this.props.banners.length > 0) {
            this.timer = setInterval(() => {
                let newIndex = this.state.index + 1;
                if (newIndex > this.props.banners.length - 1) newIndex = 0;
                this.setState({index: newIndex})
                this.triggerBannerChanged();
            }, this.props.delay * 1000);
        }
    }

    triggerBannerChanged() {
        if (this.props.banners.length === 0) return; // Nothing to update

        // if (this.props.onBannerChanged && this.props.banners.length > 0) this.props.onBannerChanged(this.props.banners[this.state.index], this.state.index);
        this.context.stats.stat(LogSpace.LocationsMisc, {
            location: this.context.location.meta.id,
            statObjType: 'BANNER',
            statObjId: this.props.banners[this.state.index].placementId,
            statType: 'V',
            // 'url' => $url,
            category: this.context.category ? this.context.category.id : 0,
            sortorder: this.state.index + 1
        });

    }

    handleBannerClick(ev, banner, ix) {
        ev.preventDefault();
        // if (this.props.onBannerClicked) this.props.onBannerClicked(banner, ix);
        this.context.openUrl(banner.url);
        this.context.stats.stat(LogSpace.LocationsMisc, {
            location: this.context.location.meta.id,
            statObjType: 'BANNER',
            statObjId: banner.placementId,
            statType: 'C',
            url: banner.url,
            category: this.context.category ? this.context.category.id : 0,
            sortorder: ix + 1
        })
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }
}

export default RotatingBanners