import React from 'react';

import './Modal.css';

class Modal extends React.Component {
    render() {
        return <div className="Modal-Overlay" onClick={this.handleClickOutside}>
            <div className="Modal" style={this.props.style ? this.props.style : {}} onClick={e => e.stopPropagation()}>
                {this.props.children}
            </div>
        </div>
    }

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.allowClickOutside) this.closeModal();
    }

    handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.closeModal();
    }

    closeModal() {
        if (this.props.onClose) this.props.onClose();
    }
}

export default Modal