import React from 'react';

// Assets
import './Map.css';

// Components
import CachedImage from '../../../CachedImage';

// Contexts
import { LocationContext } from '../../../../contexts/Location';

// Utils
import { mapImageUrl, addressStr } from '../../../../utils/Url';
import config from '../../../../config/config';

class Map extends React.Component {
    render() {
        const brochure = this.props.brochure;

        if (brochure.addresses.length === 0) return null;

        const address = brochure.addresses[0];
        const mapAddress = addressStr(address);

        const mapWidth = config.instance.map.width;
        const mapHeight = config.instance.map.height;
        // const staticMapUrl = 'https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=' + encodeURIComponent(mapAddress) + '&ie=UTF8';
        const staticMapImgUrl = mapImageUrl(mapAddress, mapWidth, mapHeight);

        // const staticMap = <a href={staticMapUrl} target="_blank"><img src={staticMapImgUrl} width={mapWidth} height={mapHeight} border="0" /></a>;
        return <LocationContext.Consumer>{({httpCache}) => (
            <CachedImage httpCache={httpCache} src={staticMapImgUrl} width={mapWidth} height={mapHeight} className="brochure-Map" alt="Google Map" />
        )}</LocationContext.Consumer>

        // if (address.latitude.length === 0 || address.longitude.length === 0) {
        //     return staticMap;
        // }
        //
        // const mapId = 'brochure_map-' + brochure.id;
        // return (
        //     <>
        //         <div id={mapId} style={{width:'100%', height:mapHeight + 'px'}}></div>
        //         {this.initMapOnPoint(mapId, address.latitude, address.longitude)}
        //     </>
        // );
    }

    // initMapOnPoint(mapId, lat, lng) {
    //     const maps = window.google.maps;
    //
    //     var latlng = new maps.LatLng(lat, lng);
    //     var myOptions = {
    //         zoom: 12,
    //         center:latlng,
    //         mapTypeId: maps.MapTypeId.ROADMAP
    //     };
    //     // var map = new maps.Map(document.getElementById(mapId), myOptions);
    //     // var marker = new maps.Marker({
    //     //     position: new maps.LatLng(lat, lng),
    //     //     map: map
    //     // });
    // }
}

export default Map