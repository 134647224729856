import React from 'react';
import PropTypes from 'prop-types';

// Assets
import './ScreenTakeoverWarning.css';

// Components
import Modal from './Modal';
import Dialog from './Dialog';
import Button from './Button';

class ScreenTakeoverWarning extends React.Component {
    render() {
        return <Modal>
            <div className="ScreenTakeoverWarning-dialog">
                <Dialog>
                    <div className="ScreenTakeoverWarning-msg">{this.props.restartLabel} {this.props.count}</div>
                    <Button label={this.props.cancelLabel} onClick={this.props.onCancel}/>
                </Dialog>
            </div>
        </Modal>
    }
}

ScreenTakeoverWarning.props = {
    restartLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
};

export default ScreenTakeoverWarning