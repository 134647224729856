import React from 'react';
import loadingSpinner from '../assets/img/loading-square.svg';
import './LoadingSpinner.css';

class LoadingSpinner extends React.Component {
    render() {
        return <div className={this.classes()}><img src={loadingSpinner} alt="Loading spinner" /> {this.props.label ? <label>{this.props.label}</label> : null}</div>
    }

    classes() {
        const classes = ['LoadingSpinner'];
        classes.push(this.sizeClass());
        classes.push(this.speedClass());

        return classes.join(' ');
    }

    sizeClass() {
        return this.props.size ? this.props.size : 'normal';
    }

    speedClass() {
        return this.props.speed ? this.props.speed + '-speed' : 'standard-speed';
    }
}

export default LoadingSpinner