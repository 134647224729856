import ActionHandler from './ActionHandler';
import ActionProcessor from '../ActionProcessor';

class ClearLocationCacheAction implements ActionHandler {
    process(data: { [p: string]: any }, processor: ActionProcessor): Promise<any|void> {
        if (processor.app.httpCache) {
            const cache = processor.app.httpCache;

            return cache.keys()
                .then(keys => {
                    processor.app.toastManager.alert('Cache cleared: ' + keys.length);
                    return Promise.all(keys.map(key => cache.delete(key)));
                })
                .then(() => {
                    processor.app.toastManager.alert('Refreshing...');
                    return processor.app.refresh(true);
                });
        } else {
            processor.app.toastManager.alert('Cache clearing not supported');
        }
        return Promise.resolve(undefined);
    }
}

export default ClearLocationCacheAction