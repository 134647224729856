import React from 'react';

import { LocationContext } from '../../../../contexts/Location';
import CachedImage from '../../../CachedImage';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return <LocationContext.Consumer>{({httpCache}) => (
            <div className="rack-Footer">
                <div className="rack-Footer-support"/>
                <div className="rack-Footer-center">
                    <div className="rack-Footer-body">
                        <div className="rack-Footer-brand">
                            <div className="rack-Footer-inset">
                                <div className="rack-Footer-inset">
                                    {/*Page {this.props.curPage} of {this.props.numPages} ({this.props.providers ? this.props.providers.length : 'NONE'})*/}
                                    {this.props.providers && this.props.providers.map((provider, ix) => (
                                        <button key={ix + '-' + provider.id} className="rack-Footer-provider"
                                                onClick={() => this.props.onProviderClicked(provider)}>
                                            <CachedImage httpCache={httpCache} src={provider.logo} alt={provider.name}/>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rack-Footer-shadow"/>
                </div>
                <div className="rack-Footer-support"/>
            </div>
        )}</LocationContext.Consumer>
    }
}

export default Footer