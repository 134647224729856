const brochureDetail = {
    publicationDetail: 'Broschüre Informationen',
    detail: 'Detail',
    address: 'Anschrift',
    description: 'Bezeichnung',
    website: 'Webseite',
    reservations: 'Reservierungen',
    phone: 'Telefon',
    map: 'Karte',
    video: 'Video',
    photos: 'Fotos',
    addToSuitcase: 'Sparen',
    inSuitcase: 'Gerettet',
    downloadPrint: 'Herunterladen',
    print: 'Drucken',
    shareWithFriend: 'Aktie',
    shareBrochure: 'Broschüre teilen',
    shareBrochureDescription: 'Senden Sie eine Kopie der Informationen dieser Broschüre an sich selbst oder einen Freund.'
};

export default brochureDetail;