// /*global chrome*/
import React from 'react';
import Dialog from '../../../Dialog';
import Modal from '../../../Modal';
import SuitcaseView from '../../../suitcase/Suitcase';

import {LocationContext} from '../../../../contexts/Location';
// import suitcaseImg from '../../../../assets/img/tpl/suitcase.png';
import txtMySuitcaseImg from '../../../../assets/img/tpl/txt-mysuitcase.svg';
import suitcaseImg from '../../../../assets/img/tpl/suitcase.svg';
import './Suitcase.css';
import config from '../../../../config/config';

class Suitcase extends React.Component {
    render() {
        // let keepAwakeSupported = chrome && chrome.power && chrome.power.requestKeepAwake;
        return <LocationContext.Consumer>{({translations, suitcase, saveBrochure, showSuitcase, hideSuitcase, suitcaseVisible}) => (
            <div className="location-bar-Suitcase">
                <button onClick={showSuitcase} className="location-bar-Suitcase-button">
                    <img src={txtMySuitcaseImg} alt="My Suitcase"/><br/>
                    <img src={suitcaseImg} alt="Suitcase"/>
                </button>
                <div className="location-bar-Suitcase-counter"><span>{this.props.showVersion ? config.instance.kioskVersion + ' (' + config.instance.kioskId + '.' + config.instance.kioskRegistrationId + ')' : suitcase.length}</span></div>
                { suitcaseVisible ?
                    <Modal allowClickOutside={true} onClose={hideSuitcase}>
                        <div style={this.suitcaseStyle(suitcase)}>
                            <Dialog title={translations.suitcase.yourSuitcase} onClose={hideSuitcase}>
                                <div style={{padding: '20px'}}>
                                    <SuitcaseView brochures={suitcase}/>
                                </div>
                            </Dialog>
                        </div>
                    </Modal>
                    :
                    null
                }
            </div>
        )}</LocationContext.Consumer>
    }

    suitcaseStyle(suitcase) {
        return {
            width: suitcase.length > 0 ? 'auto' : '300px',
            margin: suitcase.length > 0 ? '20px' : '20px auto'
        };
    }
}

export default Suitcase