import React from 'react';
import {LocationContext} from '../../../../contexts/Location';

// Assets
import './Categories.css';
import whiteCategoryArrow from '../../../../assets/img/tpl/category-arrow-white.svg';
import blackCategoryArrow from '../../../../assets/img/tpl/category-arrow-black.svg';

// Components
import RoundedButton from '../../../RoundedButton';

class Categories extends React.Component {
    render() {
        const categories = this.props.categories.map(category => {
            const selected = this.props.category && this.props.category.name === category.name;
            return <div key={category.id} className="location-bar-Category">
                <RoundedButton onClick={() => this.props.onChangeCategory(category)} className={'FullWidth LeftAlign Category Category-' + category.key + (selected ? ' selected':'')}>
                    {category.translatedName} <img src={selected ? blackCategoryArrow : whiteCategoryArrow} alt="Category marker" />
                </RoundedButton>
            </div>
        });

        return <LocationContext.Consumer>{app => {
            const selected = this.props.category ? false : true;
            return <div className="location-bar-Categories">
                <h2>{app.translations.general['Select a category below:']}</h2>
                <div className="location-bar-Category">
                    <RoundedButton className={'FullWidth LeftAlign Category Category-all' + (selected ? ' selected' : '')} onClick={() => this.props.onChangeCategory()}>
                        {app.translations.general['All Brochures']} <img src={selected ? blackCategoryArrow : whiteCategoryArrow} alt="Category marker"/>
                    </RoundedButton>
                </div>
                {categories}
            </div>
        }}</LocationContext.Consumer>
    }
}

export default Categories