import React from 'react';

import Button from '../Button';

import padlock from '../../assets/img/setup/padlock2.svg';
import './Kiosks.css';

class Kiosk extends React.Component {
    render() {
        return <div className="setup-Kiosks">
            <div>
                {this.props.onBack ? <Button className="LeftAlign" label="BACK" onClick={this.props.onBack}/> : null}
                <Button className="LeftAlign" label="REFRESH" onClick={this.props.onRefresh} />
            </div>
            <h2>Select a Kiosk:</h2>
            <ul className="setup-Kiosks-list">
            {this.props.kiosks.length === 0 ?
                <div>No kiosks available for registration</div>
                :
                this.props.kiosks.map(kiosk => (
                    <li key={kiosk.id}>
                        <button className="setup-Kiosks-kiosk" onClick={() => this.props.onKioskSelected(kiosk)}>
                            <div className="label">
                                {kiosk.name}
                                {kiosk.code ?
                                    <img src={padlock} style={{width: 32, height: 32, verticalAlign: 'middle', marginLeft: 5}} alt="Padlock"/>
                                    :
                                    null
                                }
                            </div>
                            <div className="arrow">&raquo;</div>
                        </button>
                    </li>
                ))
            }
            </ul>
        </div>
    }
}

Kiosk.defaultProps = {
    kiosks: []
};

export default Kiosk