import ActionHandler from './ActionHandler';
import ActionProcessor, {ActionRequest} from '../ActionProcessor';

class RestartAction implements ActionHandler {
    process(action: ActionRequest, processor: ActionProcessor): Promise<any|void> {
        return new Promise<void>((resolve, reject) => {
            processor.app.restart();
            resolve();
        })
    }
}

export default RestartAction