import ActionHandler from './ActionHandler';
import ActionProcessor from '../ActionProcessor';

class DeleteCacheFileAction implements ActionHandler {
    process(data: { [p: string]: any }, processor: ActionProcessor): Promise<any|void> {
        if (!window.caches) return Promise.resolve(false);

        return window.caches
            .open(data.cacheName)
            .then(cache => {
                return cache.keys()
                    .then(keys => {
                        return Promise.all(keys.map(key => {
                            return cache.delete(key);
                        }));
                }).then(() => true);
            });
    }
}

export default DeleteCacheFileAction