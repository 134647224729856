import { apiRequest } from './API';
import { inArray } from '../utils/Arr';
import config from '../config/config';
import { addressStr, mapImageUrl } from '../utils/Url';
import localforage from 'localforage';

const MediaType = {
    IMAGE: 'Image',
    VIDEO: 'Video'
};

class KioskLoader {
    constructor(httpCache) {
        this.httpCache = httpCache;
        this.statusChangeHandler = (status) => {};
    }

    onStatusUpdate(callback) {
        this.statusChangeHandler = callback;
    }

    changeStatus(txt) {
        this.statusChangeHandler(txt);
    }
    /**
     * @param {FileSystem} fileSystem
     */
    // constructor(fileSystem) {
    //     this.fileSystem = fileSystem;
    // }
    /**
     * Load a location's JSON file and cache it for later use
     * @returns {Promise}
     */
    load() {
        return new Promise((function(resolve, reject) {
            // First attempt to retrieve fresh copy
            return apiRequest('/kiosk/locations/self.json')
                .then(response => {
                    // Update the configured kiosk ID if this device has been reassigned
                    if (response.kioskId && response.kioskId !== config.instance.kioskId) {
                        config.instance.kioskId = response.kioskId;
                    }

                    localforage.setItem('kiosk-data', JSON.stringify(response));

                    // Do not pre-load media in demo mode
                    if (config.instance.demoMode) {
                        resolve(response);
                        return;
                    }

                    this.preloadMedia(response).then(() => resolve(response));
                })
                // If the network request fails then try the cache
                .catch(e => {
                    return localforage.getItem('kiosk-data')
                        .then(sKiosk  => {
                            // If that fails then bail
                            if (null === sKiosk) return reject(e);

                            // Otherwise return the location
                            const kiosk = JSON.parse(sKiosk);
                            return this.preloadMedia(kiosk).then(() => resolve(kiosk));
                        });
                });
        }).bind(this));
    }

    /**
     * Pre-download all required media
     */
    preloadMedia(kiosk) {
        let media = extractMediaDownloadsFromObj(kiosk); // url => type

        return new Promise(resolve => {
            this.preloadImages(media)
                .then(() => this.preloadVideos(media))
                .then(() => resolve());
        });
    }

    /**
     * Traverse through an array structure and look for images to preload
     * @param kiosk
     */
    preloadImages(media) {
        return this.cacheMediaOfType(media, MediaType.IMAGE, 'Images');
    }

    preloadVideos(media) {
        return this.cacheMediaOfType(media, MediaType.VIDEO, 'Videos');
    }

    cacheMediaOfType(media, typeName, label) {
        if (!this.httpCache) return Promise.resolve(); // return if we are not using cache API

        const promises = [];
        const downloads = this.mediaOfType(media, typeName);
        const numDownloads = downloads.length;

        if (numDownloads === 0) return Promise.resolve();

        this.changeStatus('Downloading ' + label);

        downloads.forEach(url => {
            const request = new Request(url);

            promises.push(
                this.httpCache.match(request).then(response => {
                    if (!response) {
                        const fetchOpts = {
                            // cache: 'force-cache'
                        };
                        return fetch(request.url, fetchOpts)
                            .then(response => {
                                if (!response.ok) {
                                    return; // If  response is NOT 2XX then bail
                                }

                                return this.httpCache.put(request, response)
                            })
                            .catch(e => console.warn('Failed to download:', request.url));
                    }
                })
            )
        });

        return Promise.all(promises);
    }

    mediaOfType(media, type) {
        const typeMedia = [];

        for(let url in media) {
            if (media[url] === type) typeMedia.push(url);
        }

        return typeMedia;
    }
}

/**
 * Traverse an object to find images to download
 * @param {object} obj
 * @param {object} media Lookup with URL as key and media type as value: {url: mediaType}
 */
function extractMediaDownloadsFromObj(obj, media) {
    if (!media) media = {};

    let mediaType = null;

    for (var i in obj) {
        if (null === obj[i]) continue;

        switch(typeof(obj[i])) {
            case 'object':
                // Check if the object represents an address and convert it to a MAP a string

                if (isObjectAnAddress(obj[i])) {
                    const mapConfig = config.instance.map;
                    const mapImgSrc = mapImageUrl(
                        addressStr(obj[i]),
                        mapConfig.width,
                        mapConfig.height
                    );
                    media[mapImgSrc] = MediaType.IMAGE;
                // Otherwise recurse through object to find more
                } else {
                    extractMediaDownloadsFromObj(obj[i], media) ;
                }

                break;
            case 'string':
                mediaType = getMediaTypeFromString(obj[i])
                if (null !== mediaType) media[obj[i]] = mediaType;
                break;
            case 'number':
            case 'float':
            case 'boolean':
                break;
            default:
                console.log('Unknown type for pre-load: ', typeof(obj[i]), i);
        }
    }

    return media;
}

function isObjectAnAddress(obj) {
    return obj.hasOwnProperty('street1') && obj.hasOwnProperty('street2') && obj.hasOwnProperty('city') && obj.hasOwnProperty('state') && obj.hasOwnProperty('postalCode');
}
/**
 * Prefetch images
 * @param {string} str
 */
function getMediaTypeFromString(str) {
    const extension = str.substring(str.lastIndexOf('.')+1);
    let mediaType = null;
    if (str.substr(0, 4) !== 'http') return mediaType;

    if (inArray(extension, ['jpg', 'jpeg', 'png', 'gif', 'svg'])) {
        mediaType = MediaType.IMAGE;
    } else if (inArray(extension, ['mp4'])) {
        mediaType = MediaType.VIDEO;
    }

    return mediaType;
}

export { KioskLoader }