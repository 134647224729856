import React from 'react';
import PropTypes from 'prop-types';

import CachedImage from '../../../CachedImage';
import {LocationContext} from '../../../../contexts/Location';
import './HotDeals.css';

class HotDeals extends React.Component {
    render() {
        return <LocationContext.Consumer>{({httpCache, translations}) => {
            return <div className="HotDeals">
                <h1>{translations.general.hotDeals}</h1>

                <div className="HotDeals-deals">
                    {this.filteredDeals().map((deal, ix) => {
                        return <button key={deal.id} className="HotDeals-deal"
                                onClick={ev => this.handleClick(ev, deal, ix)}>
                            <CachedImage httpCache={httpCache} src={deal.imageUrl} alt="Hot deal"/>
                        </button>
                    })}
                </div>
            </div>
        }}</LocationContext.Consumer>
    }

    filteredDeals() {
        return this.props.hotDeals.filter(deal => {
            return deal.imageUrl.length > 0 && deal.url.length > 0;
        });
    }
    
    componentDidMount() {
        this.filteredDeals().forEach((deal, ix) => {
            this.props.onDealView(deal, ix)
        });
    }

    componentDidUpdate(prevProps) {
        this.filteredDeals().forEach((deal, ix) => {
            this.props.onDealView(deal, ix)
        });
    }

    handleClick(ev, deal, ix) {
        ev.preventDefault();
        this.props.onDealClick(deal, ix);
    }
}

HotDeals.propTypes = {
   onDealView: PropTypes.func.isRequired,
   onDealClick: PropTypes.func.isRequired,
};

HotDeals.defaultProps = {
    hotDeals: []
};

export default HotDeals