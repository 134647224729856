import React from 'react';
import PropTypes from 'prop-types';

// Assets
import './ScreenSelector.css';

// Components
import Modal from '../Modal';
import RoundedButton from '../RoundedButton';

class ScreenSelector extends React.Component {
    render() {
        return <Modal>
            <div className="ScreenSelector">
                <div className="ScreenSelector-description">Select a View</div>
                <div className="ScreenSelector-options">
                    {this.props.showIxes.map(ix => (
                        <RoundedButton key={this.props.screens[ix].key} label={this.props.screens[ix].name} className={'ChangeLocationScreen' + (ix === this.props.currentScreenIx ? ' Selected':'')} onClick={() => this.props.onSelectScreen(ix)}/>
                    ))}
                </div>
            </div>
        </Modal>
    }
}

ScreenSelector.propTypes = {
    screens: PropTypes.array.isRequired,
    showIxes: PropTypes.array.isRequired,
    currentScreenIx: PropTypes.number // The currently selected screen ix
}
export default ScreenSelector