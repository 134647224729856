import React from 'react';
import PropTypes from 'prop-types';

import ToastMessage from '../services/ToastMessage';

import './Toast.css';

class Toast extends React.Component {
    render() {
        return <div className="Toast">{this.props.message.message}</div>
    }
}

Toast.propTypes = {
    message: PropTypes.instanceOf(ToastMessage)
}

export default Toast