/**
 * Returns a cached image
 */
import React from 'react';
import PropTypes from 'prop-types';

class CachedVideo extends React.Component {
    render() {
        const {httpCache, src, videoRef, ...videoProps} = this.props;

        return this.state.src === null ?
            'Loading'
            :
            <video ref={videoRef} src={this.state.src} {...videoProps}/>;
    }

    /**
     * Get cached version of video URL, if available, otherwise return video src directly
     */
    prepareVideoSrc() {
        if (!this.props.httpCache) { // httpCache should be defined, but just in case not....
            this.setState({
                src: this.props.src
            });
            return;
        }

        const request = new Request(this.props.src);

        this.props.httpCache.match(request).then(response => {
            if (response) {
                response.blob().then(blob => {
                    this.setState({
                        src: window.URL.createObjectURL(blob)
                    });
                });
            } else {
                this.setState({
                    src: this.props.src
                });
            }
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            src: null
        }
    }

    componentDidMount() {
        this.prepareVideoSrc();
    }

    // Update image if prop.src changes
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.src !== prevProps.src) this.prepareImgSrc();
    }
}

CachedVideo.propTypes = {
    httpCache: PropTypes.object,
    src: PropTypes.string.isRequired
}

export default CachedVideo