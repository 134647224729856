import React from 'react';
import Button from '../Button';
import CachedImage from "../CachedImage";

import PropTypes from 'prop-types';

import './Brochure.css';

class Brochure extends React.Component {
    render() {
        const brochure = this.props.brochure;

        return <div className="suitcase-Brochure">

            {this.media()}
            <div className="suitcase-Brochure-content">
                <p><strong>{brochure.name}</strong></p>
                <p>{brochure.addresses[0].street1}<br/>{brochure.addresses[0].city}, {brochure.addresses[0].state} {brochure.addresses[0].postalCode}</p>
                <p>Phone:</p>
                <p><Button label="REMOVE" onClick={this.props.onRemove}/></p>
            </div>
        </div>
    }

    media() {
        const media = this.props.brochure.media;
        const frontImages = media.filter(media => media.mediaKey === 'brochure-front' && media.variation === '0');
        const backImages = media.filter(media => media.mediaKey === 'brochure-back' && media.variation === '0')

        const frontImage = frontImages.length > 0 ? frontImages[0].url : null;
        const backImage = backImages.length > 0 ? backImages[0].url : null;

        let images = [frontImage];
        if (backImage) images.push(backImage);

        return images.map((image, ix) => <div className="suitcase-Brochure-image" key={image}><CachedImage httpCache={this.props.httpCache} key={ix} src={image} alt="Brochure" /></div>);
    }
}

Brochure.propTypes = {
    httpCache: PropTypes.object
}

export default Brochure