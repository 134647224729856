import ActionHandler from './ActionHandler';
import ActionProcessor from '../ActionProcessor';

class ReloadDataAction implements ActionHandler {
    static ACTION='Restart';

    process(data: { [p: string]: any }, processor: ActionProcessor): Promise<any|void> {
        return new Promise<void>((resolve, reject) => {
            processor.app.refresh().then(() => {
                processor.app.toastManager.alert('Refreshing...');
                resolve();
            });
        })
    }
}

export default ReloadDataAction