import React, {FunctionComponent} from 'react';
import ReactQRCode from 'react-qr-code';
type QRCodeProps = {
    content: string
    size?: number
}
/**
 * @param props Any option that can be set on QRCode - most import {content: 'https://www.domain.com/'}
 * @returns {*}
 * @constructor
 * @see https://www.npmjs.com/package/qrcode-svg
 */
const QRCode: FunctionComponent<QRCodeProps> = (props) => {
    return <ReactQRCode size={props.size ?? 150} value={props.content}/>

    // return <div className="QRCode" dangerouslySetInnerHTML={{__html: qrcode.svg()}}/>
}

export default QRCode