const general = {
    unknownErrorTitle: 'Unknown Error',
    unknownErrorDescription: 'An unknown error occurred.  Please try again shortly.',
    loading: 'Loading...',
    sending: 'Sending',
    sendEmail: 'Send email',
    sendText: 'Send text',
    submittingDotDotDot: 'Submitting...',
    backTo: 'Back to',
    close: 'Close',
    restarting: 'Restarting',
    cancel: 'Cancel',

    // Template
    travelResources: 'Travel Resources',
    travelInfoGuides: 'Travel Info Guide',
    travelGuides: 'Travel Guides',
    quickLinks: 'Quick Links',
    privacyPolicy: 'Privacy Policy',
    numBrochuresSaved: '%d Brochure(s) Saved',
    saving: 'Saving',
    hotDeals: 'Hot Deals',
    'Select City': 'Select City',
    'Select Area': 'Select Area',
    'Select a category below:': 'Select a Category Below:',
    funAtYourFingerTips: 'Fun at Your Fingertips',
    qrCodeInstructions: 'Scan the QR code on the left with your mobile phone or tablet to open the website.',
    qrCodeHint: 'hint: use the camera app on your iOS and Android',

    // Categories
    'All Brochures': 'All Brochures',
    'Main Destination Rack': 'Main Destination Rack',
    'Accommodations': 'Accommodations',
    'Attractions & Activities': 'Attractions & Activities',
    'Dining & Entertainment': 'Dining & Entertainment',
    'Shopping': 'Shopping',
    'Tours & Transportation': 'Tours & Transportation',
    'Visitor Services': 'Visitor Services'
};

export default general;
