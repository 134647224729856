const detail = {
    publicationDetail: 'Información Folleto',
    detail: 'Información',
    address: 'Dirección',
    description: 'Descripción',
    website: 'Pagina Web',
    reservations: 'Reservas',
    phone: 'Telefono',
    map: 'Mapa',
    video: 'Vídeo',
    photos: 'Fotos',
    addToSuitcase: 'Salvar',
    inSuitcase: 'Salvado',
    downloadPrint: 'Descargar',
    print: 'Imprimir',
    shareWithFriend: 'Partager',
    shareBrochure: 'Compartir folleto',
    shareBrochureDescription: 'Envíe una copia de la información de este folleto a usted o a un amigo.'
};

export default detail;