import React from 'react';
import PropTypes from 'prop-types';
import localforage from 'localforage';

// Assets
import './Setup.css';
import logo from '../../assets/img/logo.svg';

// Components
import Dialog from '../Dialog';
import Kiosks from './Kiosks';
import LoadingSpinner from '../LoadingSpinner';
import CodePad from './CodePad';

// Utils
import { apiRequest, apiPostData } from '../../services/API';
import config from '../../config/config';

const MSG_CONNECTING = 'Connecting to server...';

const SetupMode = {
    Unregister: 'Unregister',
    Register: 'Register'
}

class Setup extends React.Component {
    render() {
        return <div style={{textAlign: 'center'}}>
            <img src={logo} className="Header-logo" alt="VisitorTips Logo" onContextMenu={() => this.unregister()}/><br/>
            {config.instance.kioskVersion} {this.registrationLabel()}

            <div className="Setup-dialog">
                <Dialog>
                    <h1>{SetupMode[this.state.mode]} Kiosk</h1>

                    {this.state.showMessage ?
                        <div className="Setup-status">
                            <LoadingSpinner speed="fast"/> <span className="message">{this.state.message}</span>
                        </div>
                        :
                        null
                    }

                    {this.state.showCodePad ?
                        <CodePad
                            onBack={this.state.mode === SetupMode.Unregister ? this.handleCodePadBack.bind(this) : null}
                            onSubmit={this.handleCodePadSubmit.bind(this)}
                        />
                        :
                        null
                    }
                    {this.state.showKioskSelection ?
                        <Kiosks
                            kiosk={this.state.kiosk}
                            kiosks={this.state.kiosks}
                            onBack={this.handleKioskSelectionBackClick.bind(this)}
                            onRefresh={this.handleKioskRefresh.bind(this)}
                            onKioskSelected={this.handleKioskSelected.bind(this)}
                        />
                        :
                        null
                    }
                </Dialog>
            </div>
        </div>
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.getLoadedState(),
            kiosk: props.kiosk,
            code: '',
            showCodePad: true, // props.kiosk ? true : false,
            showKioskSelection: false,
            mode: props.kiosk ? SetupMode.Unregister : SetupMode.Register
        };
    }

    getLoadedState() {
        return {
            kiosks: [
                // {id: 1, name: 'Kiosk Name', code: true}
            ],
            showCodePad: true,
            showMessage: false,
            message: MSG_CONNECTING
        };
    }

    componentDidMount() {
        this.reset();
    }

    reset(mode) {
        const newState = this.getLoadedState();
        if (mode) newState.mode = mode;
        this.setState(newState);
    }

    handleKioskSelected(kiosk) {
        this.setState({
            message: 'Registering...',
            showMessage: true,
            kiosk: kiosk,
            showCodePad: false,
            showKioskSelection: false
        });
        setTimeout(() => {
            apiPostData('/kiosk/registration/register.json', {
                kioskId: this.state.kiosk.id,
                registrationId: this.state.kiosk.registrationId,
                code: this.state.code
            })
                .then(data => {
                    if (data.success === true) {
                        this.setState({
                            message: 'Success',
                        })
                        setTimeout(() => {
                            this.props.onKiosk(this.state.kiosk);
                        }, 2000);
                    }
                })
                .catch(() => {
                    this.setState({
                        message: 'Server error.  Try again'
                    });
                    setTimeout(() => {
                        this.setState({
                            showCodePad: true,
                            showMessage: false
                        });
                    }, 2000);
                });
        }, 2000);
    }

    handleCodePadBack() {
        if (this.state.mode === SetupMode.Unregister) {
            this.props.onKiosk(this.state.kiosk);
        }
    }

    handleCodePadSubmit(code) {
        this.setState({
            code: code,
            showCodePad: false,
            showMessage: true,
            message: 'Verifying...'
        });

        if (this.state.mode === SetupMode.Register) {
            this.registerWithCode(code);
        } else if (this.state.mode === SetupMode.Unregister) {
            this.unregisterWithCode(code);
        } else {
            this.setState({
                message: 'Invalid setup mode: ' + this.state.mode
            });
        }
    }

    registrationLabel() {
        let lbl = '';

        if (this.state.kiosk) lbl += 'Reg ' + this.state.kiosk.id + '.' + this.state.kiosk.registrationId;

        return lbl;
    }

    registerWithCode(code) {
        apiRequest('/kiosk/registration/validatecode.json?code=' + code).then(data => {
            if (data.success === true) {
                if (data.kiosks.length === 0) {
                    this.setState({
                        message: 'No kiosks available for that registration',
                    });
                    setTimeout(() => {
                        this.setState({
                            showMessage: false,
                            showCodePad: true
                        });
                    }, 2000);
                // } else if (data.kiosks.length === 1) {
                //     this.setState({
                //         message: 'Success',
                //         kiosk: data.kiosks[0]
                //     })
                //     this.props.onKiosk(this.state.kiosk);
                } else {
                    this.setState({
                        showMessage: false,//message: 'Select configuration',
                        kiosks: data.kiosks,
                        showCodePad: false,
                        showKioskSelection: true
                    });
                }
            } else {
                this.setState({
                    message: 'Invalid code: ' + data.message
                });
                setTimeout(() => {
                    this.setState({
                        showMessage: false,
                        showCodePad: true
                    });
                }, 2000);
            }
        }).catch(e => {
            this.setState({
                message: 'Server error.  Try again: ' + e
            });
            setTimeout(() => {
                this.setState({
                    showMessage: false,
                    showCodePad: true
                });
            }, 2000);
        });
    }

    unregisterWithCode(code) {
        console.log('Requesting device de-registration');
        apiPostData('/kiosk/registration/unregister.json', {
            code
        }).then(data => {
            if (data.success === true) {
                this.unregister();
            } else {
                console.warn('Failed to unregister device: ', data.error);

                this.setState({
                    message: 'Failed: ' + data.error
                })

                setTimeout(() => {
                    this.setState({
                        showCodePad: true,
                        showMessage: false
                    });
                }, 2000);
            }
        }).catch(err => {
            console.error('Server error ' + err);
            this.setState({
                message: 'Server error.  Try again.'
            });
            setTimeout(() => {
                this.setState({
                    showCodePad: true,
                    showMessage: false
                });
            }, 2000)
        });
    }

    unregister() {
        console.log('Device unregistered');

        this.setState({
            kiosk: null,
            message: 'Unregistered from server',
        });

        localforage.removeItem('kiosk')
            .then(() => {
                console.log('Removed from localforage');

                this.setState({
                    message: 'Removed from localforage'
                });

                return new Promise(resolve => setTimeout(() => resolve(), 2000));
            })
            .then(() => {
                return localforage.removeItem('kiosk-data')
                    .then(() => {
                        console.log('Kiosk data removed');
                    });
            })
            .then(() => {
                if (window.caches && config.instance.useCacheApi) {
                    console.log('Purging window.caches');
                    return window.caches.delete('location')
                        .then(() => {
                            console.log('Purged HTTP cache');
                            this.setState({message: 'Purged HTTP cache'});
                        })
                        .catch(err => {
                            console.error('Failed to purge HTTP cache');
                            this.setState({message: 'Failed to purge HTTP cache'});
                        });
                } else {
                    console.log('Skipping HTTP cache purge: window.caches:', window.caches ? 'Yes':'No', 'config.useCacheApi:', config.instance.useCacheApi ? 'Yes':'No');

                    this.setState({message: 'Skipped HTTP cache purge'});
                }
            }).then(() => {

            console.log('Kiosk unregistered');
            this.setState({message: 'Kiosk unregistered'});

            setTimeout(() => {
                this.props.onKiosk(this.state.kiosk);
            }, 2000);
        });
    }

    handleKioskRefresh() {
        this.setState({
            message: 'Refreshing...',
            showMessage: true,
            showKioskSelection: false,
            kiosks: []
        });
        setTimeout(() => {
            this.registerWithCode(this.state.code);
        }, 1000);
    }

    handleKioskSelectionBackClick() {
        this.setState({
            showCodePad: true,
            showKioskSelection: false
        });
    }

}

Setup.propTypes = {
    kiosk: PropTypes.object,
    onKiosk: PropTypes.func.isRequired
};

export default Setup