/* global chrome */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

// Assets
import logo from './assets/img/logo.svg';

// Components
import Demo from './components/setup/Demo';
import Initialization from './components/Initialization';
import LayoutBuilder from './components/screens/LayoutBuilder';
import LoadingSpinner from './components/LoadingSpinner';
import LocationRackScreen from './components/screens/LocationRackScreen';
import ScreenCropBox from "./components/ScreenCropBox";
import ScreenManager from './components/screens/ScreenManager';
import Setup from './components/setup/Setup';
import ToastList from './components/ToastList';

// Utils / Services
import ActionProcessor from './services/ActionProcessor';
import config from './config/config';
import Heartbeat from './services/Heartbeat';
import Stats, {LogSpace} from "./services/Stats";
import {KioskLoader} from './services/KioskLoader';
import localforage from 'localforage';
import ToastManager from './services/ToastManager';
import {AppContext, translations} from './contexts/App';
import ScreenTakeoverWarning from './components/ScreenTakeoverWarning';
import ScreenTakeover from './components/ScreenTakeover';
import ScreenManagerService from './services/ScreenManager';

const AppMode = {
    STARTING: 'Starting',
    INITIALIZING: 'Initializing',
    SETUP: 'Setup',
    LOADING: 'Loading',
    LOADED: 'Loaded'
};

const LAST_RESTARTED = 'lastRestarted';
const IS_RESTARTING = 'isRestarting';

class App extends React.Component {
    /**
     * Render this bad boy
     * @returns {XML}
     */
    render() {
        return <div className={'App ' + config.instance.appCssClass}>
            <AppContext.Provider value={{
                changeLocale: this.changeLocale.bind(this),
                getIdleTime: this.getIdleTime.bind(this),
                getSecondsSinceLastScreenTakeoverFinished: this.getSecondsSinceLastScreenTakeoverFinished.bind(this),
                locale: this.state.locale,
                translations: this.state.translations,
                takeoverRunning: this.state.takeoverRunning
            }}>
                <Router>
                    <Switch>
                        <Route path="/h:code" render={props => this.demoView(props)}/>
                        <Route path="/" render={() => this.primaryView()}/>
                    </Switch>
                    {config.instance.showCropBox ?
                        <ScreenCropBox width={1920} height={1080}/>
                        :
                        null
                    }
                </Router>
            </AppContext.Provider>
            <ToastList messages={this.state.toastMessages}/>
        </div>
    }

    demoView(props) {
        config.instance.demoMode = true;
        return this.state.kiosk || (!props.match || !props.match.params) ? this.primaryView() : <Demo onKiosk={this.demoKiosk.bind(this)} code={props.match.params.code}/>;
    }

    primaryView() {
        let view = null;
        switch(this.state.mode) {
            case AppMode.STARTING:
                break;
            case AppMode.INITIALIZING:
                view = <Initialization onInitialized={this.onInitialized.bind(this)}/>;
                break;
             case AppMode.SETUP:
                 view = <Setup kiosk={this.state.kiosk}
                               onKiosk={this.setKiosk.bind(this)}
                 />;
                 break;
             case AppMode.LOADING:
                 view = <div className="App-loading">
                     <div className="App-loading-details">
                         <img src={logo} alt="VisitorTips Logo" className="logo"/><br/>
                         {config.instance.kioskVersion}<br/>
                         <LoadingSpinner/> {this.state.statusText}
                     </div>
                 </div>;
                 break;
             case AppMode.LOADED:
                 view = <>
                     <ScreenManager httpCache={this.httpCache}
                                    heartbeat={this.heartbeat}
                                    stats={this.stats}
                                    toastManager={this.toastManager}
                                    media={this.state.media}
                                    screens={this.state.screens}
                                    screenManager={this.screenManager}
                                    onExit={() => this.setState({mode: AppMode.SETUP})}
                                    updateLastUserInteraction={this.updateLastUserInteraction.bind(this)}
                     />
                     {this.state.displayTakeoverWarning ?
                         <ScreenTakeoverWarning restartLabel={translations.general.restarting}
                                                cancelLabel={translations.general.cancel}
                                                count={this.state.takeoverWarningCounter}
                                                onCancel={this.updateLastUserInteraction.bind(this)}
                         />
                         :
                         null
                     }
                     {this.state.takeoverRunning ?
                         <ScreenTakeover media={this.state.takeovers[this.state.takeoverIx]}
                                         onRequestClose={this.handleScreenTakeoverRequestClose.bind(this)}
                                         onFinished={this.handleScreenTakeoverFinished.bind(this)}
                                         httpCache={this.httpCache}
                         />
                         :
                         null
                     }
                 </>;
                 break;
            default:
                view = <div>Error loading</div>
         }

        return view;
    }

    /**
     * Check if the screen takeover should be run
     */
    checkRunScreenTakeover() {
        if (this.takeoverWarningTimer || this.state.takeoverRunning) return; // Already running

        const lastFinished = this.getSecondsSinceLastScreenTakeoverFinished();

        // Check idle time against screen takeover timeout less the amount of time that a warning should be displayed for inactivity
        const timeout = config.instance['screenTakeover.timeout'] - config.instance['screenTakeover.warningInterval'];

        // Make sure that the screen takeover warning is only displayed when the kiosk has sat in idle for longer than timeout AND that a screen takeover has not been played sooner than the timeout period
        if (this.getIdleTime() > timeout && (lastFinished == null || lastFinished > timeout)) {
            const takeoverWarningCounter = config.instance['screenTakeover.warningInterval'];

            if (takeoverWarningCounter > 0) { // Use a reset-warning counter only if takeoverWarningCounter is positive
                this.setState({
                    displayTakeoverWarning: true,
                    takeoverWarningCounter
                });

                this.takeoverWarningTimer = setInterval(() => {
                    const count = this.state.takeoverWarningCounter - 1;
                    if (count < 0) {
                        clearInterval(this.takeoverWarningTimer);
                        this.takeoverWarningTimer = null;
                        this.openScreenTakeover();
                        // this.setState({displayTakeoverWarning: false});
                        return;
                    }
                    this.setState({
                        takeoverWarningCounter: count
                    });
                }, 1000);
            } else { // takeover warning is disabled
                this.openScreenTakeover();
            }
        }
    }
    /**
     * Show the next screen takeover
     */
    openScreenTakeover() {
        const takeovers = this.state.takeovers;

        this.screenManager.triggerResetState();

        if (!takeovers || takeovers.length === 0) { // No takeovers to display
            this.setState(this.getResetState());
            this.triggerInactive(); // Only refresh the data when the user is not interacting with the device
            this.lastScreenTakeoverFinishedTime = new Date(); // Pretend like screen takeover ran so that this is not triggered until a new timeout tirgger has been initiated
            return;
        } else if (this.state.takeoverRunning) {
            return;
        } // Takeover is already running, so we cannot run another one

        let takeoverIx = this.state.takeoverIx + 1;
        if (takeoverIx > takeovers.length-1) takeoverIx = 0;

        this.setState({
            ...this.getResetState(),
            takeoverIx,
            takeoverRunning: true
        });

        this.stats.stat(LogSpace.LocationsMisc, {
            location: takeovers[takeoverIx].locationId,
            statObjType: 'TAKEOVER',
            statObjId: takeovers[takeoverIx].id,
            statType: 'V',
            category: this.state.selectedCategory ? this.state.selectedCategory.id : 0,
            sortorder: takeoverIx + 1
        });
    }

    /**
     * Close the screen takeover
     */
    closeScreenTakeover(wasInterupted) {
        const takeovers = this.state.takeovers;

        this.lastScreenTakeoverFinishedTime = new Date();
        this.setState({
            takeoverRunning: false
        }, () => {
            this.stats.stat(LogSpace.LocationsMisc, {
                location: takeovers[this.state.takeoverIx].locationId,
                statObjType: 'TAKEOVER',
                statObjId: takeovers[this.state.takeoverIx].id,
                statType: 'X' + (wasInterupted ? 'I' : 'F'), // XI = Close Interupted XF = Close Finished
                category: this.state.selectedCategory ? this.state.selectedCategory.id : 0,
                sortorder: this.state.takeoverIx + 1
            });

            if (wasInterupted) {
                this.updateLastUserInteraction();
            } else {
                this.triggerInactive(); // Only refresh the data when the user is not interacting with the device
            }
        });
    }

    /**
     * Number of seconds that the app has been in idle (no user interaction)
     * @returns {number|null} NULL if never run
     */
    getSecondsSinceLastScreenTakeoverFinished() {
        return this.lastScreenTakeoverFinishedTime === null ?
            null
            :
            ((new Date()).getTime() - this.lastScreenTakeoverFinishedTime.getTime()) / 1000
    }

    /**
     * Handle when the user explicitly attempts to close the screen takeover
     */
    handleScreenTakeoverRequestClose() {
        this.updateLastUserInteraction();
        this.closeScreenTakeover(true);
    }

    /**
     * Handle when the screentakeover is finished (e.g. the video is done playing)
     */
    handleScreenTakeoverFinished() {
        this.closeScreenTakeover(false);
    }

    componentDidMount() {
        this.preloadHttpCache()
            .then(() => {
                localforage.getItem('kiosk').then(kiosk => {
                    if (kiosk !== null) {
                        this.updateConfigWithKiosk(kiosk);
                    }
                    this.setState({
                        kiosk,
                        mode: AppMode.INITIALIZING
                    });
                });
            });
        this.heartbeat.onTick(this.heartbeatDidTick.bind(this));
    }

    componentWillUnmount() {
        this.heartbeat.removeOnTick(this.heartbeatDidTick)
    }

    /**
     * Handle heartbeat tick
     */
    heartbeatDidTick(status, lastSent) {
        this.checkRunScreenTakeover();
    }

    onInitialized() {
        this.setupPhoneHome();

        if (config.instance.kioskId) {
            localforage.getItem(IS_RESTARTING).then(isRestarting => {
                if (isRestarting) {
                    this.toastManager.alert('Restart Complete');
                    return localforage.removeItem(IS_RESTARTING) // Remove "restarting" status
                        .then(() => localforage.setItem(LAST_RESTARTED, (new Date()).getTime())); // Update "last restarted" with "now"
                }
            }).then(() => {
                this.loadKiosk(true);
                this.heartbeat.start();
            });

        } else {
            this.setState({mode: AppMode.SETUP});
            this.heartbeat.start();
        }
    }

    setupPhoneHome() {
        this.heartbeat.onPhonedHome((isOnline, updateAvailable, actions) => {
            this.checkRunScheduledRestart();

            if (!isOnline) return;
            this.stats.send();
            if (updateAvailable) this.updateAvailable = true;
            if (actions) this.processActions(actions);
        });
    }

    processActions(actions) {
        if (!Array.isArray(actions)) return;

        ActionProcessor.create(this).processActions(actions);
    }
    /**
     * Checks if scheduled restart needs to occur
     */
    checkRunScheduledRestart() {
        var now = new Date();

        if (!this.isScheduledRestartAllowedNow()) return;
        // if (now.getHours() >= 7 && now.getHours() <= 19) return; // If not in scheduled restart window then bail

        if (this.checkingShouldRestart) return; // If checkRestart() is still processing from a previous request then bail
        this.checkingShouldRestart = true;

        localforage.getItem(LAST_RESTARTED).then(lastRestarted => {
            var lastRestartMinutes = null;
            var restartThresholdMinutes = config.instance.power.scheduledRestart.interval;

            var timeNow = now.getTime();

            if (lastRestarted) { // Last restarted set
                lastRestartMinutes = Math.floor((timeNow - lastRestarted) / 1000 / 60);

                if (lastRestartMinutes >= restartThresholdMinutes) {
                    this.toastManager.alert('Restart threshold: ' + lastRestartMinutes + ' >= ' + restartThresholdMinutes).then(() => {
                        this.restart();
                        this.heartbeat.stop();
                    });
                    return;
                }
                this.checkingShouldRestart = false;
            } else { // Last restarted not set.  If we have reached this point then we are within the restart window so this box should be restarted
                this.restart();
            }
        });
    }

    isScheduledRestartAllowedNow() {
        const restartWindows = config.instance.power.scheduledRestart.windows;

        var now = new Date();
        var nowMinutes = now.getHours() * 60 + now.getMinutes();

        let allowed = false;

        for(let i=0; i < restartWindows.length; i++) {
            let restartWindow = restartWindows[i];
            let startTime = restartWindow[0].split(':');
            let startHours = parseInt(startTime[0]);
            let startMinutes = parseInt(startTime[1]);

            let endTime = restartWindow[1].split(':');
            let endHours = parseInt(endTime[0]);
            let endMinutes = parseInt(endTime[1]);

            var startTimeMinutes = startHours * 60 + startMinutes;
            var endTimeMinutes = endHours * 60 + endMinutes;

            if (nowMinutes >= startTimeMinutes && nowMinutes <= endTimeMinutes) allowed = true;
        }

        return allowed;
    }

    /**
     * Restart the kiosk box
     */
    restart() {
        this.toastManager.alert('Restarting');

        if (!window.postMessage) {
            console.log('restart.No Post Message Support');
            return; // Not supported
        }

        localforage.setItem(IS_RESTARTING, true).then(() => {
            const msg = {action: 'Restart'};
            window.postMessage(msg, "*");
            if (chrome && chrome.runtime && chrome.runtime.sendMessage) chrome.runtime.sendMessage(msg);
        });
    }

    refresh(showLoadingStatus) {
        return this.loadKiosk(showLoadingStatus).then(() => {
            this.updateAvailable = false;
        });
    }

    triggerInactive() {
        // Only refresh data if we are inactive and there is an update available
        if (this.updateAvailable) {
            this.refresh();
        }
    }

    loadKiosk(showLoadingStatus) {
        const loader = new KioskLoader(this.httpCache);
        if (showLoadingStatus) {
            this.setState({
                mode: AppMode.LOADING,
                statusText: 'Loading'
            });
        }

        loader.onStatusUpdate(text => {
            this.setState({
                statusText: text
            });
        });

        return loader.load()
            .then(response => {
                if (response.success === false && response.invalidRegistration === true) {
                    this.setKiosk(null); // Hopefully, this does not happen, but if we get here then this device should automatically de-register itself so that it can be re-registered
                    return;
                }

                this.setState({
                    media: response.media,
                    screens: response.screens,
                    takeovers: response.takeovers,
                    mode: AppMode.LOADED
                });
            })
            .catch(e => {
                console.log('Error: ', e);
            });
    }

    preloadHttpCache() {
        return new Promise(resolve => {
            if (window.caches && config.instance.useCacheApi) {
                window.caches.open('location').then(cache => {
                    this.httpCache = cache;
                    resolve(cache);
                });
            } else {
                resolve();
            } 
        });
    }

    /**
     * Mark that the user has been active
     */
    updateLastUserInteraction() {
        this.heartbeat.setLastUserInteractionTime(new Date());
        if (this.takeoverWarningTimer) {
            clearTimeout(this.takeoverWarningTimer);
            this.takeoverWarningTimer = null;
            this.setState({displayTakeoverWarning: false});
        }
    }

    /**
     * Number of seconds that the app has been in idle (no user interaction)
     * @returns {number}
     */
    getIdleTime() {
        return this.heartbeat.getLastUserInteractionTime() === null ?
            ((new Date()).getTime() - this.heartbeat.getInitTime()) / 1000 // Use seconds since launch if lastUserInteractionTime null
            :
            ((new Date()).getTime() - this.heartbeat.getLastUserInteractionTime().getTime()) / 1000 // Otherwise, use seconds since last interaction
            ;
    }

    changeLocale(locale) {
        this.updateLastUserInteraction();
        const useLocale = locale === 'en' ? 'default' : locale;
        this.setState({
            locale: locale,
            translations: translations[useLocale]
        });
    }

    getResetState() {
        return {
            locale: 'en',
            translations: translations.default,
            takeoverRunning: false
        }
    }

    initializeToastManager() {
        this.toastManager = new ToastManager();
        this.toastManager.onMessages(messages => {
            this.setState({
                toastMessages: messages
            });
        });
    }

    /**
     * @param kiosk
     */
    setKiosk(kiosk) {
        if (kiosk) { // Add settings
            localforage.setItem('kiosk', kiosk).then(() => {
                this.updateConfigWithKiosk(kiosk);
                this.setState({
                    // mode: AppMode.LOADED,
                    kiosk
                });
                this.refresh(true);
            });
        } else { // De-initialize kiosk
            this.updateConfigWithKiosk(kiosk);
            this.setState({
                kiosk,
                mode: AppMode.INITIALIZING
            });
        }
    }

    /**
     * Demonstrate kiosk without committing its information to the browser
     * @param kiosk
     */
    demoKiosk(kiosk) {
        this.updateConfigWithKiosk(kiosk);
        this.setState({
            kiosk,
            mode: AppMode.LOADING,
        });
        this.loadKiosk(true)
            .then(() => {
                this.setupPhoneHome();
                this.heartbeat.start();
            });
    }

    /**
     * Store the Kiosk IDs in the config (or clear them out if kiosk is empty
     * @param kiosk
     */
    updateConfigWithKiosk(kiosk) {
        const conf = config.instance;
        if (kiosk) { // Set up kiosk IDs
            conf.kioskId = kiosk.id;
            conf.kioskRegistrationId = kiosk.registrationId;
        } else { // Remove kiosk details
            conf.kioskId = null;
            conf.kioskRegistrationId = null;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.getResetState(),
            displayTakeoverWarning: false,
            kiosk: null,
            media: [],
            mode: AppMode.STARTING,
            online: true,
            screens: [],
            statusText: 'Initializing',
            takeovers: [],
            takeoverIx: -1, //
            toastMessages: []
        };

        this.screenManager = new ScreenManagerService();
        this.screenManager.register('location-rack', LocationRackScreen);
        this.screenManager.register('layout-builder', LayoutBuilder);

        this.updateAvailable = false;
        this.heartbeat = new Heartbeat();

        this.stats = new Stats();
        this.toastManager = null;
        this.initializeToastManager();
        this.takeoverWarningTimer = null;
        this.lastScreenTakeoverFinishedTime = null;

        window.addEventListener('message', function(e) {
            // if (e.data === 'kiosk-background') {
            //     this.toastManager.alert('Kiosk background ready', 10000);
            //     const appWindow = e.source;
            //     appWindow.sendMessage('Requesting restart');
            // } else {
            //     this.toastManager.alert('App.onMessage:' + JSON.stringify(e.data), 10000);
            // }
        });
    }
}

export default App