import React from 'react';
import PropTypes from 'prop-types';
import Toast from './Toast';

import './ToastList.css';

class ToastList extends React.Component {
    render() {
        if (this.props.messages.length === 0) return null;

        return <div className="ToastList">{this.props.messages.map(message => (
            <div className="ToastList-item" key={message.date + message.message}><Toast message={message}/></div>
        ))}</div>
    }
}

ToastList.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object)
};

export default ToastList