import React from 'react';
import PropTypes from 'prop-types';

// Assets
import logo from '../assets/img/logo.svg';
import './Initialization.css';

// Components
import LoadingSpinner from './LoadingSpinner';
import Button from './Button';

// Services / Utils / Config
import config from '../config/config';
import { apiRequest } from '../services/API';
import localforage from 'localforage';

class Initialization extends React.Component {
    render() {
        return <div className="App-loading">
            <div className="App-loading-details">
                <img src={logo} alt="VisitorTips Logo" className="logo"/><br/>
                {config.instance.kioskVersion}<br/>
                {this.state.showLoadingSpinner ? <LoadingSpinner/> : null}
                &nbsp;{this.state.statusText}<br/>
                <div className="Initialization-ip"><label>IP Address:</label> <span>{this.state.ipAddress}</span></div>
                <div className="Initialization-ip"><label>Mac Addr:</label> <span>{this.state.macAddr.length > 0 ? this.state.macAddr : 'Unknown'}</span></div>
                {this.state.allowPause ?
                    <p><Button onClick={this.handlePauseClick.bind(this)} label={this.state.paused ? 'Resume' : 'Pause'}/></p>
                    :
                    null
                }
            </div>
        </div>;
    }

    constructor(props) {
        super(props);
        this.recheckInterval = 5;
        this.timer = null;
        this.state = {
            statusText: 'Starting Up',
            ipAddress: 'Checking',
            macAddr: '',
            timesInternetChecked: 0,
            allowPause: false,
            paused: false,
            counter: this.recheckInterval,
            showLoadingSpinner: true
        };
    }

    componentDidMount() {
        localforage.getItem('macAddr')
            .then(macAddr => {
                if (macAddr) {
                    this.setState({
                        macAddr
                    })
                }
                if (config.instance.demoMode) {
                    this.props.onInitialized();
                    return;
                }

                this.checkInternet();
            });
    }

    checkInternet() {
        if (this.state.timesInternetChecked >= config.instance.maxInitConnectionChecks) {
            this.props.onInitialized();
            return;
        }
        const timesInternetChecked = this.state.timesInternetChecked + 1;
        this.setState({
            timesInternetChecked,
            statusText: 'Checking connection...'
        });

        apiRequest('/kiosk/ip.json')
            .then(response => {
                if (response.ip) {
                    const state = {
                        allowPause: true,
                        ipAddress: response.ip,
                        statusText: 'Connected.',
                        counter: config.instance.initShowInfoDelay
                    };

                    if (response.mac && response.mac.length > 0) {
                        state.macAddr = response.mac;
                        localforage.setItem('macAddr', response.mac);
                    }
                    this.setState(state);
                    if (this.timer) clearInterval(this.timer);
                    this.timer = setInterval(() => {
                        const newCount = this.state.counter - 1;
                        if (newCount <= 0) {
                            clearInterval(this.timer);
                            this.props.onInitialized();
                            return;
                        }
                        this.setState({
                            showLoadingSpinner: false,
                            statusText: 'Continuing in ' + newCount + ' seconds',
                            counter: newCount
                        });
                    }, 1000);
                } else {
                    this.recheckInternet();
                }
            })
            .catch(err => {
                this.recheckInternet();
            });
    }

    recheckInternet() {
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval((function() {
            let counter = this.state.counter;
            counter--;
            if (counter === 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.checkInternet();
                return;
            }
            this.setState({
                counter,
                statusText: 'Re-checking in ' + counter + ' seconds'
            });
        }).bind(this), 1000);
    }

    handlePauseClick() {
        if (this.state.paused) { // If the state is already paused then the next click will be to continue
            this.props.onInitialized();
        } else { // Not currently paused, so clicking will disable the countdown and pause the current state
            this.setState({
                paused: true,
                statusText: ''
            });
            clearInterval(this.timer);
        }
    }
}

Initialization.propTypes = {
    onInitialized: PropTypes.func.isRequired
};

export default Initialization