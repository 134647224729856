const brochureDetail = {
    publicationDetail: 'Publication Detail',
    detail: 'Detail',
    address: 'Address',
    description: 'Description',
    website: 'Website',
    reservations: 'Reservations',
    phone: 'Phone',
    map: 'Map',
    video: 'Video',
    photos: 'Photos',
    addToSuitcase: 'Add to Suitcase',
    inSuitcase: 'In Suitcase',
    downloadPrint: 'Download / Print',
    print: 'Print',
    shareWithFriend: 'Share with Friend',
    shareBrochure: 'Share Brochure',
    shareBrochureDescription: 'Send a copy of this brochure\'s information to yourself or a friend.'
};

export default brochureDetail;