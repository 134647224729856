import React from 'react';

import PropTypes from 'prop-types';

import './CodeValue.css';

class CodeValue extends React.Component {

    render() {
        return <div className={'setup-CodeValue' + (this.props.selected ? ' selected' : '')} onClick={() => this.props.onClick(this.props.value)}>
            {this.props.value.length > 0 ? this.props.value : '-'}
        </div>
    }
}

CodeValue.propTypes = {
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

export default CodeValue