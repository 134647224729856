// @TODO Should extend ElementTypeManager, but this was failing with a module parse error.  Possibly related to TypeScript?
// import ElementTypeManager, {ElementDefaults} from 'kiosk-shared/services/element-type-manager';
// import ComponentType from 'kiosk-shared/models/component-type';
import {ReactElement} from 'react';
export default class ScreenManager {
    private _types: {[key: string]: ReactElement} = {};
    private _resetHandlers: Array<Function> = []

    register(typeKey: string, typeDef: ReactElement) {
        this._types[typeKey] = typeDef;
    }

    getType(typeKey: string): ReactElement|null{
        return this._types[typeKey];
    }

    public get typeKeys() {
        return Object.keys(this._types);
    }

    getFirstScreenIxSupported(screens: Array<any>) {
        let screenType;
        if (!screens) return -1;

        for(let i=0, j=screens.length; i < j; i++) {
            screenType = screens[i].type;
            if (this.getType(screenType) !== undefined) return i;
        }

        // We should not get here, as at least one supported type should be found above
        return -1;
    }

    triggerResetState() {
        this._resetHandlers.forEach((handler: Function) => {
            // Call reset handler on listener
            handler();
        });
    }

    onResetState(handler: Function) {
        this._resetHandlers.push(handler);
    }

    removeOnResetState(handler: Function) {
        this._resetHandlers = this._resetHandlers.filter((existingHandler: Function) => existingHandler !== handler);
    }

    public get types() {
        return Object.keys(this._types).map(typeKey => {
            return this._types[typeKey];
        });
    }
}