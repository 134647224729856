const account = {
    notRegisteredQuestion: 'Nicht registriert?',
    registerHere: 'Hier registrieren',
    clickHere: 'Click Here',
    email: 'Email',
    textPhone: 'Texttelefon',
    passwordPrompt: 'Passwort:',
    signInTitle: 'Anmelden',
    registrationTitle: 'Neu registrieren',
    alreadyRegisteredQuestion: 'Bereits registriert?',
    signInHere: 'Hier anmelden',
    confirmPasswordPrompt: 'Passwort bestätigen:',
    postalCodePrompt: 'Postleitzahl:',
    missingEmailAndPassword: 'Bitte geben Sie eine E-Mail und Passwort.',
    logOut: 'Abmelden',
    signIn: 'Einloggen'
};

export default account;
