const account = {
    notRegisteredQuestion: 'Non enregistré?',
    registerHere: 'Inscrivez-vous ici',
    clickHere: 'Cliquez içi',
    email: 'Email',
    textPhone: 'Téléphone texte',
    passwordPrompt: 'Mot de passe:',
    signInTitle: 'S\'inscrire',
    registrationTitle: 'Registre',
    alreadyRegisteredQuestion: 'Déjà enregistré?',
    signInHere: 'Se connecter ici',
    confirmPasswordPrompt: 'Confirmez le mot de passe:',
    postalCodePrompt: 'Code Postal:',
    missingEmailAndPassword: 'S\'il vous plaît entrer un email et un mot de passe.',
    logOut: 'Se déconnecter',
    signIn: 'Se connecter'
};

export default account;