import { apiPostData } from './API';
import config from '../config/config';

const Status = {
    queued: 'queued',
    sending: 'sending',
    sent: 'sent'
};

class Stats {
    constructor() {
        this.reset();
    }

    /**
     * Clear all queued stats.  Generally only done when starting up or when unregistering a device.
     */
    reset() {
        this.queue = [];
        this.isSending = false;
    }
    /**
     * Queue a stat to be sent by send()
     * @param {string} logSpace
     * @param {Object} data
     */
    stat(logSpace, data) {
        if (config.instance.demoMode) return; // Do not send stats in demo mode

        // Add stat to queue
        this.queue.push({
            date: (new Date()),
            logSpace,
            data,
            status: Status.queued
        });
    }

    /**
     * Send the queued stats
     */
    send() {
        if (config.instance.demoMode) return; // Do not send stats in demo mode
        if (this.queue.length === 0) return; // Nothing to do, let's bail

        this.isSending = true; // Prevent send() from being run multiple times simultaneously

        const maxIx = this.queue.length - 1;
        const sendStats = [];

        for(let ix=0; ix <= maxIx; ix++) {
            if (this.queue[ix].status !== Status.queued) continue;
            this.queue[ix].status = Status.sending;
            sendStats.push({
                time: Math.round(this.queue[ix].date.getTime() / 1000),
                ...this.queue[ix].data,
                logSpace: this.queue[ix].logSpace
            });
        }

        apiPostData('/kiosk/stats/collector.json', {
            stats: sendStats
        }).then(() => {
            for (let ix=maxIx; ix >= 0; ix--) {
                if (this.queue[ix].status === Status.sending) {
                    this.queue.splice(ix, 1);
                }
            }
            this.isSending = false;
        }).catch(err => {
            console.error('Stats error ' + err);
            for (let ix=0; ix <= maxIx; ix ++) {
                if (!this.queue[ix]) continue; // May not exist during erro, so skip
                
                if (this.queue[ix].status === Status.sending) {
                    this.queue[ix].status = Status.queued;
                }
            }

            this.isSending = false;
        });
    }
}

export class LogSpace {
    static LocationsMisc = 'locations-misc';
    static Locations = 'locations';
    static Listings = 'listings';
    static Screen = 'screen';
}

export default Stats