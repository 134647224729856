import React from 'react';
import Brochure from './Brochure';
import MockBrochure from './MockBrochure';
import './Pocket.css';
import config from '../../../../config/config';

class Pocket extends React.Component {
    render() {
        if (this.props.mockBrochure) return this.mockedBrochure();

        const brochure = this.props.brochure;
        const maxHeight = Math.floor(config.instance.targetBrochureWidth * 2.25);
        return <div className="rack-Pocket" style={{maxHeight}}><Brochure brochure={brochure} onBrochureClicked={this.props.onBrochureClicked}/></div>
    }

    mockedBrochure() {
        return <div className="rack-Pocket"><MockBrochure/></div>
    }
}

export default Pocket