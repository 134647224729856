// Assets
import './Rack.css';
import leftArrow from '../../../../assets/img/racks/left-yellow.png';
import rightArrow from '../../../../assets/img/racks/right-yellow.png';

// Components
import React from 'react';
import Footer from './Footer';
import Row from './Row';
import Section from './Section';

// Utils
import { chunk } from '../../../../utils/Arr';
import config from '../../../../config/config';

class Rack extends React.Component {
    render() {
        if (!this.props.brochures && !this.props.mockRows) return null;

        const maxBrochureWidth = config.instance.targetBrochureWidth;
        const maxWidth = this.props.cols * maxBrochureWidth;

        return (
            <div className="Rack" style={{maxWidth: maxWidth + 'px'}}>
                <Section>{this.brochureRows()}</Section>
                <Footer curPage={this.state.curPage + 1} numPages={this.getNumPages()} providers={this.props.providers} onProviderClicked={this.props.onProviderClicked} />
                {this.hasPrevPage() ? <button className="Rack-left-arrow" onClick={() => this.prevPage()}><img src={leftArrow} alt="Left arrow"/></button> : null }
                {this.hasNextPage() ? <button className="Rack-right-arrow" onClick={() => this.nextPage()}><img src={rightArrow} alt="Right arrow"/></button> : null }
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            curPage: 0
        }
    }

    componentDidMount() {
        this.triggerViews();
    }

    componentDidUpdate(prevProps) {
        if (prevProps && this.props.category !== prevProps.category) {
            // Reset page if category filter is changed
            this.setState({
                curPage: 0
            });
        }

        this.triggerViews(prevProps);
    }

    hasPrevPage() {
        return this.state.curPage > 0;
    }

    prevPage() {
        if (this.hasPrevPage()) this.setState({curPage: this.state.curPage-1});
        if (this.props.onPageChange) this.props.onPageChange(this.state.curPage);
    }

    hasNextPage() {
        return this.state.curPage < this.getNumPages() - 1;
    }

    nextPage() {
        if (this.hasNextPage()) this.setState({curPage: this.state.curPage+1});
        if (this.props.onPageChange) this.props.onPageChange(this.state.curPage);
    }

    getNumPages() {
        return this.props.brochures ? Math.ceil(this.props.brochures.length / this.props.cols / this.props.rowsPerPage) : 0;
    }

    /**
     * Rendered rows
     */
    brochureRows() {
        if (this.props.mockRows) return this.mockedBrochureRows(); // Allow mock brochure rack to be created as a placeholder

        const fromIx = this.state.curPage * this.props.rowsPerPage;
        const toIx = fromIx + this.props.rowsPerPage;

        const brochureRows = chunk(this.repeatedBrochures(), this.props.cols).slice(fromIx, toIx);

        return brochureRows.map((brochures, rowIx)=> {
            const numCols = brochureRows[0].length; // The first row indicates the number of columns (future rows may not have every row filled

            return <Row key={rowIx} brochures={brochures} onBrochureClicked={(brochure, colIx) => this.props.onBrochureClicked(brochure, this.brochureIndex(numCols, rowIx, colIx), rowIx, colIx)}/>;
        });
    }

    triggerViews(prevProps) {
        if (!this.props.brochures || !this.props.onRackBrochureView) return;

        const fromIx = this.state.curPage * this.props.rowsPerPage;
        const toIx = fromIx + this.props.rowsPerPage;

        this.props.brochures.forEach((brochure, ix) => {
            const isVisible = ix >= fromIx && ix <= toIx;
            this.props.onRackBrochureView(brochure, ix, isVisible);
        });
    }

    mockedBrochureRows() {
        const rows = [];

        for(let i=0; i < this.props.mockRows; i++) {
            rows.push(<Row key={i} mockBrochures={this.props.cols}/>);
        }

        return rows;
    }

    /**
     * Ensure that every row has the same number of pockets (only when more than one row)
     * @returns {*}
     */
    repeatedBrochures() {
        let brochures = [...this.props.brochures];
        let lastRowCount = brochures.length % this.props.cols; // Number of brochures in the last row
        let padSize = brochures.length > this.props.cols ? this.props.cols - lastRowCount : 0;

        for(let x=0; x < padSize; x++) {
            brochures.push(brochures[x]);
        }

        return brochures;
    }

    /**
     * Calculate the index of a brochure using its row and column indexes
     * @param numColsPerRow
     * @param rowIx
     * @param colIx
     * @returns {*}
     */
    brochureIndex(numColsPerRow, rowIx, colIx) {
        return (rowIx * numColsPerRow) + colIx;
    }
}

export default Rack