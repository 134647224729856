/*global chrome*/
/**
 * Centralized timer so that timers do not have to be created all over the place
 */
import config from '../config/config';
import {apiPostData} from '../services/API';

const Status = {
    ALIVE: 'Alive',
    DEAD: 'Dead'
};

class Heartbeat {
    constructor() {
        this.tickHandlers = []; // Every time the timer is called
        this.debugMessageHandlers = []; // Every time the timer is called
        this.phonedHomeHandlers = []; // Every time the hearbeat is sent to the server
        this.statusChangedHandlers = [];
        this.timer = null;
        this.interval = 1000;
        this.lastSent = null; // time
        this.sendingHeartbeat = false;
        this.status = Status.ALIVE;
        this.initTime = new Date();
        this.lastUserInteractionTime = null;
    }

    /**
     * @returns {null|Date}
     */
    getLastUserInteractionTime() {
        return this.lastUserInteractionTime;
    }
    /**
     * @param {Date} lastUserInteractionTime
     */
    setLastUserInteractionTime(lastUserInteractionTime) {
        this.lastUserInteractionTime = lastUserInteractionTime;
    }

    /**
     * @returns {Date}
     */
    getInitTime() {
        return this.initTime;
    }

    start() {
        this.stop();
        this._debugMessage('Heartbeat.start');
        this.timer = setInterval(() => {
            const lastSent = this.getLastSent();
            this._tick(lastSent);

            if (lastSent === null ||
                (this.status === Status.ALIVE && lastSent >= config.instance.heartbeatInterval) ||
                (this.status === Status.DEAD && lastSent >= config.instance.recheckOnlineInterval)
            ) {
                this._debugMessage('Hearbeat.phoning home');
                this.phoneHome().then(({isOnline, update, actions}) => {
                    this.lastSent = new Date();
                    this._changeStatus(isOnline ? Status.ALIVE : Status.DEAD);
                    this._debugMessage('Hearbeat.phoned home: ' + (isOnline ? 'online' : 'offline'));
                    this._phonedHome(isOnline, update, actions);
                }).catch(() => {
                    this._phonedHome(false, false, []);
                });
            }
        }, 1000);
    }

    stop() {
        this._debugMessage('Heartbeat.stop (' + (this.timer ? 'timer' : 'no timer') + ')');
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    /**
     * Call any handlers interested in receiving updates
     */
    _tick(lastSent) {
        for (let i=0; i < this.tickHandlers.length; i++) {
            this.tickHandlers[i](this.status, lastSent);
        }
    }

    /**
     * Call any handlers interested in receiving updates
     * @param {bool} isOnline Whether the device is online
     * @param {bool} update Whether the device should be update (only set if isOnline is true)
     */
    _phonedHome(isOnline, update, actions) {
        for (let i=0; i < this.phonedHomeHandlers.length; i++) {
            this.phonedHomeHandlers[i](isOnline, update, actions);
        }
    }

    /**
     * Call any handlers interested in debug messages
     */
    _debugMessage(msg) {
        for (let i=0; i < this.debugMessageHandlers.length; i++) {
            this.debugMessageHandlers[i](msg);
        }
    }

    /**
     * Listen to tick()
     * @param handler function(Date lastSent)
     */
    onTick(handler) {
        this.tickHandlers.push(handler);
    }

    /**
     * Listen to phonedHome()
     * @param handler function(isOnline)
     */
    onPhonedHome(handler) {
        this.phonedHomeHandlers.push(handler);
    }

    /**
     * Add handler to receive debug messages
     * @param handler
     */
    onDebugMessage(handler) {
        this.debugMessageHandlers.push(handler);
    }

    removeOnTick(handler) {
        this.tickHandlers = this.tickHandlers.filter(testHandler => testHandler !== handler);
    }

    removeOnPhonedHome(handler) {
        this.phonedHomeHandlers = this.phonedHomeHandlers.filter(testHandler => testHandler !== handler);
    }

    removeOnDebugMessage(handler) {
        this.debugMessageHandlers = this.debugMessageHandlers.filter(testHandler => handler !== testHandler);
    }

    /**
     * Number of seconds since last sent
     * @returns {*}
     */
    getLastSent() {
        return this.lastSent ? ((new Date()).getTime() - this.lastSent.getTime()) / 1000 : null;
    }

    /**
     * Phone home [server] to let my parents know that I am ok
     * @returns {Promise} Returns TRUE of heartbeat sent, or FALSE if not
     */
    phoneHome() {
        if (this.sendingHeartbeat) {
            return Promise.resolve(false);
        } // Prevent multiple requests from being sent at the same time
        const tm = (new Date()).getTime()/1000 | 0;
        // this.lastHeartbeatSentTime = new Date();

        this.sendingHeartbeat = true;

        return this.gatherSystemInfo().then(systemInfo => {

            // Shortcut heartbeat if we're in demo mode
            if (config.instance.demoMode) {
                return Promise.resolve({
                    isOnline: true,
                    update: false,
                    actions: []
                });
            }

            const heartbeatData = {
                time: tm,
                startTime: this.initTime.getTime() / 1000,
                lastUserInteractionTime: this.lastUserInteractionTime ? this.lastUserInteractionTime.getTime() / 1000 : null,
                lastUserInteractionAge: this.lastUserInteractionTime ? ((new Date()).getTime() - this.lastUserInteractionTime.getTime()) / 1000 : null,
                config: config.instance,
                system: systemInfo
            };

            // Indicate that this is a fresh start / restart
            if (this.lastSent === null) {
                heartbeatData.wasRestarted = true;
            }

            // Send heartbeat
            return apiPostData('/kiosk/heartbeat.json?k=' + config.instance.kioskId + '&r=' + config.instance.kioskRegistrationId, heartbeatData).then(response => {
                // Apply any config changes from remote
                // if (response.config) config.instance = {...config.instance, response.config};
                this.sendingHeartbeat = false;

                return {
                    isOnline: true,
                    update: response.update === true,
                    actions: response.actions
                };
            }).catch(() => {
                this.sendingHeartbeat = false;

                return {
                    isOnline: false,
                    update: false,
                    actions: []
                };
            });
        });
    }

    gatherSystemInfo() {
        const systemInfo = {
            memory: null,
            cpu: null
            // networks: null
        };
        return new Promise(resolve => {
            this.getMemoryInfo().then(memory => {
                systemInfo.memory = memory;
                return this.getCpuInfo();
            }).then(cpu => {
                systemInfo.cpu = cpu;
                // return this.getNetworkInfo();
            // }).then(networks => {
            //     systemInfo.networks = networks; // [] = {name, address, prefixLength}
            }).then(() => {
                resolve(systemInfo);
            });
        });
    }

    getMemoryInfo() {
        return new Promise(resolve => {
            if (chrome && chrome.system && chrome.system.memory) {
                chrome.system.memory.getInfo(memory => {
                    resolve(memory);
                });
            } else {
                resolve(null);
            }
        });
    }

    getCpuInfo() {
        return new Promise(resolve => {
            if (chrome && chrome.system && chrome.system.cpu) {
                chrome.system.cpu.getInfo.getInfo(cpu => {
                    resolve(cpu);
                });
            } else {
                resolve(null);
            }
        });
    }

    // getNetworkInfo() {
    //     return new Promise(resolve => {
    //         if (chrome && chrome.system && chrome.network) {
    //             // @see https://developer.chrome.com/apps/system_network
    //             chrome.system.network.getNetworkInterfaces(networks => {
    //                 resolve(networks);
    //             });
    //         } else {
    //             resolve(null);
    //         }
    //     });
    // }

    /**
     * Call all handlers interested in receiving status updates
     * @param status
     * @private
     */
    _changeStatus(status) {
        if (status !== this.status) {
            this.status = status;
            for(let i=0; i < this.statusChangedHandlers.length; i++) {
                this.statusChangedHandlers[i](this.status);
            }
        }
    }
}

export { Status };
export default Heartbeat;