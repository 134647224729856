import general from './general';
import suitcase from './suitcase';
import account from './account';
import brochureDetail from './brochure-detail';

const germanTranslations = {
    general,
    suitcase,
    account,
    brochureDetail
};

export default germanTranslations;
