import config from '../config/config';

function standardHeaders() {
    return {
        'Content-type': 'application/json',
        'X-Kiosk-ID': config.instance.kioskId,
        'X-Kiosk-Registration-ID': config.instance.kioskRegistrationId,
        'X-Kiosk-Version': config.instance.kioskVersion,
        'X-Kiosk-Mode': config.instance.demoMode ? 'Kiosk-Demo' : 'Kiosk',
        'X-Kiosk-Time': Math.round((new Date()).getTime() / 1000)
    };
}
/**
 *
 * @param path An absolute path to an API endpoint - do not include scheme/host, i.e. use /path/to/endpoint
 */
function apiRequest(path) {
    return fetch(config.instance.apiBaseUrl + path, {
            headers: standardHeaders()
        })
        .then(response => response.json());
}

function apiPostData(path, data) {
    return apiNonGETRequest('POST', path, data);
}

function apiPutData(path, data) {
    return apiNonGETRequest('PUT', path, data);
}

function apiNonGETRequest(method, path, data) {
    return fetch(config.instance.apiBaseUrl + path, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: standardHeaders(),
            method,
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer'
        })
        .then(response => response.json());
}

export { apiRequest, apiPostData, apiPutData }