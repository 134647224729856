import React, {createRef} from 'react';
import PropTypes from 'prop-types';

import './ScreenTakeover.css';
import CachedVideo from './CachedVideo';

class ScreenTakeover extends React.Component {
    render() {
        const media = this.props.media;
        if (!media) return null;

        return <div className="ScreenTakeover" onClick={this.handleRequestClose.bind(this)}>
            <div className="ScreenTakeover-video-container" style={{paddingTop: this.state.ratio + '%'}}>
                {this.video(media)}
            </div>
        </div>;
    }

    video(media) {
        // canPlay(e.nativeEvent.returnValue);
        return <CachedVideo autoPlay={true}
                            controls={false}
                            httpCache={this.props.httpCache}
                            onAbort={this.handleFinished.bind(this)}
                            onCanPlay={e => { /*console.log('CAN PLAY: ', e.nativeEvent)*/ }}
                            onEnded={this.handleFinished.bind(this)}
                            onError={this.handleFinished.bind(this)}
                            onLoadedMetadata={this.handleLoadedMetadata.bind(this)}
                            onPlaying={e => {/*  console.log('Playing: ', e) */}}
                            onPlay={e => {/*console.log('Play: ', e) */}}
                            onClick={this.handleRequestClose.bind(this)}
                            src={media.url}
                            videoRef={this.videoRef}
        />
        // return <video ref="video" controls={false} src={media.url} autoPlay={true}
        //               onLoadedMetadata={this.handleLoadedMetadata.bind(this)}
        //               onEnded={this.handleFinished.bind(this)}
        //               onAbort={this.handleFinished.bind(this)}
        //               onError={this.handleFinished.bind(this)}
        //               onCanPlay={e => console.log('CAN PLAY: ', e)}
        //               onPlaying={e => console.log('Playing: ', e)}
        //               onPlay={e => console.log('Play: ', e)}
        //             // onPause={this.handlePause.bind(this)}
        //             // onPlay={this.handleEvent('onPlay').bind(this)}
        //             // onPlaying={this.handleEvent('onPlaying').bind(this)}
        //             //   onProgress={this.handleProgress.bind()} // Not reliable as implemented here
        //             // onSuspend={this.handleEvent('onSuspend').bind(this)}
        //               onClick={this.handleRequestClose.bind(this)}
        // />;
    }

    /**
     * Set the actual size of the video once it is known
     */
    handleLoadedMetadata(e) {
        const video = this.videoRef.current;
        // console.log('handleLoadedMetadata: ', video);
        if (video) {
            this.setState({
                ratio: video.videoHeight / video.videoWidth * 100
            });
        }
    }

    /**
     * Handle when the video has ended
     * @param e
     */
    handleFinished(e) {
        if (this.props.onFinished) this.props.onFinished();
    }

    /**
     * Handle when the video is being requested to be closed through interaction
     * @param e
     */
    handleRequestClose(e) {
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    /**
     * Update progress bar
     */
    handleProgress(e) {
        // console.log('progress: ', e.nativeEvent.target.currentTime, e.nativeEvent.target.duration);
    }

    constructor(props) {
        super(props);

        // Assume 16:9 by default
        this.state = {
            ratio: 56.25,
            progress: 50
        }

        this.videoRef = createRef();
    }
}

ScreenTakeover.propTypes = {
    httpCache: PropTypes.object,
    media: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired
};

export default ScreenTakeover