/**
 * Break array into chunks of size
 * @param arr
 * @param size
 * @returns {Array}
 */
function chunk(arr, size) {
    var chunks = [];
    for(var i=0, j=arr.length; i<j; i+=size) {
        chunks.push(arr.slice(i, i+size));
    }
    return chunks;
}

/**
 * Check if a string is in an array of strings
 * @param str
 * @param arr
 * @returns {boolean}
 */
function inArray(str, arr) {
    for(var i in arr) {
        if (arr[i] === str) return true;
    }

    return false;
}

function firstIndexOf(arr, callback) {
    for (var i=0, j=arr.length; i < j; i++) {
        if (callback(arr[i]) === true) return i;
    }

    return -1;
}

export { chunk, inArray, firstIndexOf }