const account = {
    notRegisteredQuestion: '¿No registrado?',
    registerHere: 'Registrar aquí',
    clickHere: 'Clickea aquí',
    email: 'Email',
    textPhone: 'Teléfono de texto',
    passwordPrompt: 'Clave:',
    signInTitle: 'Ingresar',
    registrationTitle: 'Registro',
    alreadyRegisteredQuestion: '¿Ya registrado?',
    signInHere: 'Firme aquí',
    confirmPasswordPrompt: 'Confirmar contraseña:',
    postalCodePrompt: 'Código Postal:',
    missingEmailAndPassword: 'Por favor, introduzca un correo electrónico y una contraseña.',
    logOut: 'Cerrar sesion',
    signIn: 'Iniciar sesión'
};

export default account;