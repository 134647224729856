import React from 'react';
import './Button.css';

class Button extends React.Component {
    render() {
        return <button className={'Button ' + (this.props.className ? this.props.className : 'Default')} onClick={this.handleClick}>{this.props.label}{this.props.children}</button>
    }

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick) this.props.onClick();
    }
}

export default Button