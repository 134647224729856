import React from 'react';
import './EmbeddedMedia.css';

class EmbeddedMedia extends React.Component {
    render() {
        if (!this.props.media || !this.props.media.html || this.props.media.html.length === 0) return 'We apologize, but this content is not available at this time';

        const html = this.props.media.html.replace(/<a.*?>(.+)<\/a>/, '$1'); // Remove any links that might create "break out" problems

        return <div className="brochure-EmbeddedMedia" dangerouslySetInnerHTML={{__html: html}}/>;
    }
}

export default EmbeddedMedia