import React from 'react';

// Assets
import suitcaseImg from '../../assets/img/tpl/suitcase.svg';
import './Suitcase.css';

// Components
import Brochure from './Brochure';
import QRCode from '../QRCode';

// Utils / Contexts
import {LocationContext} from '../../contexts/Location';
import { mobileUrl } from '../../utils/Url';

class Suitcase extends React.Component {
    render() {
        return <LocationContext.Consumer>{({httpCache, saveBrochure}) => {
            return <div className={'Suitcase ' + (this.props.brochures.length === 0 ? 'empty' : '')}>
                {
                    this.props.brochures.length > 0 ?
                        this.suitcase(httpCache, saveBrochure) :
                        this.empty()
                }
            </div>
        }}</LocationContext.Consumer>
    }

    suitcase(httpCache, saveBrochure) {
        return <div className="Suitcase-container">
            <div className="Suitcase-brochures">
                {this.brochures(httpCache, saveBrochure)}
            </div>
            {this.download()}
        </div>
    }

    brochures(httpCache, saveBrochure) {
        return this.props.brochures.map(brochure => (
            <div className="Suitcase-brochure-detail" key={brochure.id}><Brochure httpCache={httpCache} key={brochure.id} brochure={brochure} onRemove={() => saveBrochure(brochure)}/></div>
        ));
    }

    empty() {
        return <div>
            <img src={suitcaseImg} alt="Suitcase"/>
            <div style={{textAlign: 'center'}}>0 Item(s)</div>
        </div>
    }

    download() {
        const ids = this.props.brochures.map(brochure => {
            return brochure.id;
        });

        return <div className="Suitcase-download">
            <h2>On-the-go</h2>

            <QRCode content={mobileUrl('/suitcase/?additem=' + ids.join(','))}/>

            <p>Take your saved brochures with you by scanning the QR code above with your mobile phone or tablet.</p>
            <p><em>* hint: use the camera app on your iOS and Android</em></p>


        </div>
    }

    constructor(props) {
        super(props);
        this.state = {
            online: true,
            qrLoaded: false
        };
    }
}

export default Suitcase